import styled from "styled-components";

export const Card = styled.div`
  background-color: ${p => p.theme.color.white};
  border-radius: ${p => p.theme.radius.large};
  padding: 32px;
  box-shadow: ${p => p.theme.shadow};
  gap: 24px;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: calc(100% - 64px);

  ${p => p.theme.mq.small} {
    padding: 24px;
    width: calc(100% - 48px);
  }
`;