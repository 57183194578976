import { PDF_HUMANIDADES_FOLDER_ID } from "../../app/api";
import { Card } from "../../components/Card";
import { Divider } from "../../components/Divider";
import { FileSystemOneDrive } from "../../components/FileSystemOneDrive";
import { Column } from "../../components/Flex";
import { Paragraph, Title } from "../../components/Text";

export const PDFHumanidadesCard = () => {
  return (
    <Card>
      <Title>Material de Estudio</Title>
      <Paragraph>
        Bienvenidxs al sitio de material digitalizado de Humanidades por el Proyecto, conducción del Centro de Estudiantes de la Facultad de Humanidades de la UNMDP.
        <br />
        <br />
        Como conducción del Centro de Estudiantes tenemos la responsabilidad y el compromiso de generar políticas que fomenten el acceso, la permanencia y el egreso a la educación pública universitaria. PDF Huma fue creado con el objetivo de facilitar y garantizar el acceso al material de estudio para todxs lxs estudiantes de Humanidades. Aquí vas a encontrar material actualizado de todas las materias y seminarios dictados en nuestra facultad. 
        <br />
        <br />
        Desde HxEP creemos en la importancia de la democratización de la información, por eso en un contexto en el cual cada vez es más difícil acceder a las impresiones o copias físicas, el acceso al material digitalizado se vuelve imprescindible para nuestra permanencia en la Universidad Pública. En este sentido, desde PDF Huma vas a poder descargar todos los textos digitalizados en archivos PDF de manera libre y gratuita para mayor facilidad de lectura. 
        <br />
        <br />
        Además, desde esta pantalla también vas a poder acceder al número de WhatsApp del Centro de Copiados, en caso de que quieras imprimir alguno de estos archivos.
      </Paragraph>
      <Column gap='8px'>
        <Divider />
        <FileSystemOneDrive rootFolderId={PDF_HUMANIDADES_FOLDER_ID} search={true}/>
      </Column>
    </Card>
  );
};
