
import { Paragraph, Subtitle, Title } from "../../components/Text";

export const ConquistasCard = () => {
  return (
    <>
      <Title>Fechas de Asueto Académico</Title>
      <Subtitle>8M - Día Internacional de la Mujer</Subtitle>
      <Paragraph>
      El 8 de Marzo se conmemora el fallecimiento de 123 jóvenes trabajadoras y en el trágico incendio de la fábrica Triangle Shirtwaist en Nueva York, en 1911. Establecido como Día Internacional de la Mujer Trabajadora por la ONU, retomamos este día como la oportunidad tanto para reivindicar nuestras conquistas como estudiantes, trabajadorxs, mujeres y disidencias, así como para seguir exigiendo nuevas libertades y garantías para construir una sociedad más igualitaria y con perspectiva de género transversal a todas las áreas de la misma. 
      <br />Como parte de nuestras conquistas de derechos estudiantiles, la Facultad de Humanidades establece este día como asueto académico, permitiendo que las mujeres y disidencias podamos formar parte de la organización y movilización del día de la mujer.
      </Paragraph>
      <Subtitle>3J - Ni Una menos</Subtitle>
      <Paragraph>
      El 3 de Junio se conmemora el día de Ni Una Menos por el Femicidio de Chiara Paez que en 2015 impulsó un proceso de  crecimiento y organización del movimiento feminista. Como estudiantes mujeres y disidencias entendemos que somos sujetos políticos activos en la lucha, conquista y defensa de  nuestros derechos, por lo que nos organizamos todos los años. 
      <br />Desde la Secretaría de Géneros y Disidencias, lxs compañerxs de Humanidades Por el Proyecto movilizamos todos los años en esta fecha. Para que nignunx compañerx tenga que poner en riesgo su continuidad académica; como parte de nuestros derechos estudiantiles, la Facultad de Humanidades sostiene asueto académico este día. 
      </Paragraph>
      <Subtitle>25N - Día de la Eliminación contra la Violencia de Género</Subtitle>
      <Paragraph>
      El 25 de noviembre desde 1999 se conmemora el Día Internacional de Eliminación de la Violencia contra las Mujeres, en memoria a las hermanas activistas Mirabel de República Dominicana.
      <br />Desde 2021 como conducción del Centro de Estudiantes y representantes estudiantiles en el Consejo Académico logramos constituir al 25 de Noviembre como Asueto Académico, permitiendo que todxs lxs compañerxs de humanidades podamos formar parte de la movilización de ese día.
      </Paragraph>
      {/*<Paragraph>
      <Link href='https://drive.google.com/file/d/15BwGZ3OdJACDu24hmq9cDpPvX9Ag2plg/view' accent target='_blank'>Link</Link> para acceder a la guía práctica para el uso de lenguaje inclusivo.
  </Paragraph>*/}
    </>
  );
};