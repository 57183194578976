
import { Card } from "../../components/Card";
import { Icon, icons } from "../../components/Icon";
import { Title } from "../../components/Text";
import { Subtitle } from "../../components/Text";
import AuxImage from "../../app/images/organiza-aux.JPG";
import Image from "../../components/Image";
import { Divider } from "../../components/Divider";

export const OrganizaCard = () => {
  return (
    <Card id='reglamentos'>
      <Title>
        {/*<Icon as={icons.file} />*/}
        Calendario Académico
      </Title>
      <Image src={AuxImage} alt='Calendario Académico - Humanidades x El Proyecto'></Image>
      <Divider style={{ marginBottom: "10px" }} />
      <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EXb8h6_38idIi9YZBs6QlHABr5gViFxsk8_7jWToNhhinw?e=ze4yt4">
          <Subtitle>
            <Icon as={icons.file} />
            OCA Calendario Acádemico 2024
          </Subtitle>
      </a>
    </Card>
  );
};
