import styled from "styled-components";
import { addProp } from "../app/util";

export const Flex = styled.div`
  display: flex;
  gap: 16px;
  background-color: ${p => (p.show ? "red" : "unset")};

  ${p => addProp("flex", p.flex)}
  ${p => addProp("flex-direction", p.flexDirection)}
  ${p => addProp("flex-wrap", p.flexWrap)}
  ${p => addProp("align-items", p.alignItems)}
  ${p => addProp("justify-content", p.justifyContent)}
  ${p => addProp("align-self", p.alignSelf)}
  ${p => addProp("gap", p.gap)}
  ${p => addProp("column-gap", p.columnGap)}
  ${p => addProp("row-gap", p.rowGap)}
  ${p => addProp("height", p.height)}
  ${p => addProp("width", p.width)}
  ${p => addProp("max-height", p.maxHeight)}
  ${p => addProp("max-width", p.maxWidth)}
  ${p => addProp("min-height", p.minHeight)}
  ${p => addProp("min-width", p.minWidth)}
  ${p => addProp("padding", p.padding)}
`;

export const Column = props => <Flex {...props} flexDirection='column' />;

export const Row = props => <Flex {...props} flexDirection='row' />;
