import styled, { keyframes } from "styled-components";

const loadAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.span`
  --main-color: ${p => p.theme.color.accent};

  font-size: 2.5px;
  margin: 0 auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: var(--main-color);
  background: linear-gradient(to right, var(--main-color) 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: ${loadAnim} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: var(--main-color);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }

  &:after {
    background: ${p => p.theme.color.white};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
