import styled from "styled-components";
import { Card } from "../../components/Card";
import { Expandable } from "../../components/Expandable";
import { Icon, icons } from "../../components/Icon";
import { Link } from "../../components/Link";
import { Title } from "../../components/Text";
import { Divider } from "../../components/Divider";


export const FaqCard = () => {
  return (
    <>
      <Card>
        <Title>
          <Icon as={icons.faq} />
          Preguntas Frecuentes
        </Title>
        <Expandable title="¿Qué es el SIU Guaraní?">
          El SIU Guaraní es el sistema de gestión de la Universidad que registra
          todas nuestras actividades académicas (notas, inscripción a materias y
          a finales) desde que somos ingresantes hasta que nos graduamos. <br />
          En el mismo, también podemos hacer trámites como pedir certificados de
          alumnx regular, promedio y porcentaje de la carrera. Podés acceder a
          él desde el siguiente link:{" "}
          <Link
            href="https://portalsiu.mdp.edu.ar/autogestion/acceso
            "
            accent
            target="_blank"
          >
            SIU Guaraní
          </Link>
        </Expandable>
        <Expandable title="¿Cómo me inscribo a las materias?">
          Si ya tenés acceso al SIU Guaraní, te dirigís al apartado "Inscripción
          a materias" en la esquina superior izquierda de la pantalla, tanto
          desde el celular como desde una computadora. Durante el período que
          dure la inscripción vas a poder seleccionar las materias que desees
          cursar para anotarte en cada una de ellas.
          <br />
          No te olvides de que debés anotarte tanto a las materias del primer
          cuatrimestre (aproximadamente en marzo) como a las del segundo
          (aproximadamente en agosto).
        </Expandable>
        <Expandable title="¿Cómo me inscribo a las mesas de examen?">
          Las mesas de examen pueden ser tanto para rendir un final como para
          pasar notas de materias promocionadas.
          <br />
          Para realizar la inscripción, en primer lugar te dirigís al apartado
          "Inscripción Exámenes" en la barra superior de opciones. Durante el
          período que dure la inscripción, vas a poder anotarte en las mesas
          necesarias.
          <br />
          ¡Importante! No todas las mesas te habilitan para rendir final, es
          importante revisar el Calendario Académico para distinguir cuáles son
          para pasar promociones y específicamente, en cuáles se puede rendir
          final.
        </Expandable>
        <Expandable title="Solicitud de certificados">
          <List>
            <ListItem>
              Certificado de alumnx regular: se puede descargar a través del SIU
              Guaraní en la pestaña de Trámites {">"} Solicitar Constancias y
              Certificados {">"} Nueva solicitud {">"} Constancia de alumnx
              regular. En caso de que no puedas realizar el trámite a través del
              SIU, o necesites un Certificado con firma física y sello, te podés
              comunicar con Dirección Estudiantes.
            </ListItem>
            <ListItem>
              Certificado Analítico parcial: se puede descargar a través del SIU
              Guaraní como Historia Académica o se puede pedir a Dirección
              Estudiantes vía mail (
              <a href="mailto:{alumnosh2023@gmail.com}">
                alumnosh2023@gmail.com
              </a>
              ).
            </ListItem>
            <ListItem>
              Certificado de porcentaje de materias: se pide a Dirección
              Estudiantes vía mail (
              <a href="mailto:{alumnosh2023@gmail.com}">
                alumnosh2023@gmail.com
              </a>
              ). El porcentaje de materias aprobadas también puede verse desde
              el apartado “Reportes” en la barra de menú en el SIU Guaraní.
            </ListItem>
            <ListItem>
              Certificado de examen: podés pedirlo en el Centro de Estudiantes
              (Nivel 0 de la Facultad de Humanidades, a la derecha de la puerta
              de entrada).
            </ListItem>
          </List>
          Para solicitar cualquiera de estos certificados a Dirección
          Estudiantes es muy importante insertar en el mail los datos personales
          (Nombre completo, DNI, carrera, año de ingreso).
        </Expandable>
        <Expandable title="Calendario Académico">
          El Calendario Académico es el calendario de la Facultad que ordena las
          fechas administrativas y de cursada del año. Allí podés encontrar
          cuándo comienzan las clases, las fechas de inscripción a cursadas, las
          fechas de mesas de examen, etc. Podés consultarlo en el siguiente
          link:{" "}
          <Link
            href="https://humanidades.mdp.edu.ar/calendario-academico-2024/"
            accent
            target="_blank"
          >
            Calendario Académico
          </Link>
          .
        </Expandable>
        <Expandable title="¿Qué es el Aula Virtual?">
          El Aula Virtual es un espacio donde podemos encontrar bibliografía,
          recursos o elementos que lxs docentes suben para complementar las
          cursadas. No es obligatorio que accedamos a ella, pero es altamente
          recomendable que lo hagamos si lxs docentes lo sugieren. <br /> Cada
          materia tiene su propio espacio y se ingresa con las distintas claves
          de automatriculación que lxs docentes comunican. En algunos casos,
          recibirás esa clave por mensaje directo del SIU Guaraní luego de
          inscribirte a la cursada a principio de cuatrimestre. En otros, puede
          que la comuniquen el primer día de clases de la materia en la clase
          inaugural. <br /> Si arrancó la cursada y no la conseguiste, podés
          sumarte a los grupos de WhatsApp por materia, conformados por
          estudiantes que estén cursándola, y enmarcados en el proyecto Cursadas
          Solidarias, y solicitarla por allí. <br />Si sos ingresante y es la primera vez que ingresás, tanto tu usuario
          como tu contraseña van a ser tu número de DNI, sin puntos ni comas.
          Luego de ingresar por primera vez, se recomienda altamente cambiar la
          contraseña por motivos de seguridad, accediendo al botón “Olvidé mi
          contraseña”, debajo del apartado para ingresar usuario y contraseña. <br />
          El link al Aula Virtual es el siguiente:{" "}
          <Link
            href="https://aulafh.mdp.edu.ar/login/index.php 
            "
            accent
            target="_blank"
          >
            Aula Virtual
          </Link>
        </Expandable>
        <Expandable title="¿Cómo sé qué materias cursar?">
          Cada carrera tiene un recorrido sugerido de materias, elaborado por
          los distintos Departamentos, en donde se explica cuál es la oferta
          académica (es decir, la oferta de materias de ese cuatrimestre) y qué
          te conviene cursar cada cuatrimestre. Podés encontrar el recorrido
          sugerido de cada carrera en nuestras redes por carrera, que encontrás
          en el apartado “carreras”.
        </Expandable>
        <Expandable title="¿Cómo valido materias de otras trayectorias académicas?">
          Existen dos trámites correspondientes para las personas que tienen
          recorridos estudiantiles previos. El primero es para quienes tienen
          título docente de instituciones de Nivel Superior No Universitario
          (ISFD) y la Licenciatura o el Profesorado de Ciencias de la Educación;
          y el segundo, para el resto de las solicitudes (estudios terciarios
          incompletos y estudios universitarios completos e incompletos).
          <br />
          En el caso de estudiantes de Ciencias de la Educación que quieran
          homologar materias tienen que presentar por Mesa de Entradas (para
          consultas, el mail es{" "}
          <a href="mailto:{mesaentradashum@mdp.edu.ar}">
            mesaentradashum@mdp.edu.ar
          </a>
          ):
          <ListItem>
            Nota firmada (podés encontrar el modelo aca:{" "}
            <Link
              href="https://drive.google.com/file/d/1_eYecfLSPiwo-qfs-TLNQ9skREk2-7IM/view?usp=sharing).
            "
              accent
              target="_blank"
            >
              Nota firmada
            </Link>
            ).
          </ListItem>
          <ListItem>
            Copia autenticada del título legalizado (hasta el año 1995 con el
            sello del Ministerio de Educación; entre 1996 y 2010 con el sello
            del Ministerio del Interior; a partir de 2010 con sello de Copia
            Fiel).
          </ListItem>
          <br />
          En el caso de estudios terciarios incompletos, o universitarios
          completos o incompletos quienes quieran validar materias tienen que
          presentar por Mesa de Entradas (para consultas el mail es{" "}
          <a href="mailto{mesaentradashum@mdp.edu.ar}">
            mesaentradashum@mdp.edu.ar
          </a>
          ):
          <ListItem>
            Analítico Parcial o Final de la institución de orígen
          </ListItem>
          <ListItem>Programas Foliados de las materias a considerar</ListItem>
          <ListItem>
            Nota firmada (podés encontrar el modelo acá:{" "}
            <Link
              href="https://drive.google.com/file/d/1_eYecfLSPiwo-qfs-TLNQ9skREk2-7IM/view?usp=sharing)
            "
              accent
              target="_blank"
            >
              Nota firmada
            </Link>
            ).
          </ListItem>
          <br />
          La fecha de presentación de la documentación es en la segunda mitad
          del año, suele darse entre septiembre y octubre, pero podés
          comunicarte con nosotrxs o con Dirección Estudiantes ({" "}
          <a href="mailto:{alumnosh2023@gmail.com}">alumnosh2023@gmail.com</a>)
          para consultar.
        </Expandable>
        <Expandable title="Becas ofrecidas por la UNMdP">
          <List>
            <ListItem>
              Ayuda Económica: Consiste en el pago de 10 cuotas de dinero, que
              se efectúa mes a mes.
            </ListItem>
            <ListItem>
              Beca de Apuntes: Consiste en la cobertura parcial o total del
              material de estudios fotocopiado que solicitan las cátedras.
            </ListItem>
            <ListItem>
              Beca Comedor Universitario: Consiste en la cobertura del costo de
              una comida diaria en el Comedor Universitario.
            </ListItem>
          </List>
          <br />
          Podés consultar las fechas de inscripción y la modalidad de los
          trámites para cualquiera de estas Becas en el siguiente link:{" "}
          <Link
            href="https://www.mdp.edu.ar/index.php/ensenanza/becas/102-becas-de-grado/becas-unmdp"
            accent
            target="_blank"
          >
            Becas UNMdP
          </Link>
          . <br />
          <br />
          Servicio Social Universitario:
          <ListItem>
            Mail: <a href="mailto:{ becassu@mdp.edu.ar}">becassu@mdp.edu.ar</a>
          </ListItem>
          <ListItem>  Mail SSU Humanidades: <a href="mailto:{ssu.huma@gmail.com}">ssu.huma@gmail.com</a></ListItem>
        </Expandable>
        <Divider />
        <Expandable title="ABC de lxs estudiantes">
          <List>
            <ListItem>
              ¿Qué es un PTD?
              <br />
              El Programa de Trabajo Docente (PTD) es un documento en el que se
              encuentra toda la bibliografía de la materia, las condiciones de
              cursada, la conformación de la cátedra y la cantidad de horas de
              cursada por semana. Cada materia que cursamos tiene su propio PTD.
              Allí queda asentado cómo va a desarrollarse la cursada, por eso es
              fundamental leerlo antes de que comience. Podés conseguir los PTDs
              de las materias en el Centro de Copiados de Humanidades (Nivel 0,
              primera puerta a la izquierda), en el Aula Virtual de la materia o
              bien solicitárselo a lx docente a cargo de esa materia en
              particular.
            </ListItem>
            <br />
            <ListItem>
              Teórico y práctico
              <br />
              “Teórico” y “práctico” son dos formas cortas con las que nos
              referimos a clases teóricas y clases prácticas. La clase teórica
              busca desarrollar los contenidos de la materia, suelen ser
              semanales, y se recomienda acompañarlas con el contenido de la
              bibliografía. Las prácticas hacen un recorrido pormenorizado por
              la bibliografía y suelen estar acompañadas de actividades grupales
              interactivas.
            </ListItem>
            <br />
            <ListItem>
              Parciales y finales
              <br />
              <ListItem>
                {" "}
                Parcial: examen durante la cursada de cada materia que cubre los
                contenidos vistos hasta el momento. Cada materia tiene dos
                parciales por cursada. La nota mínima de aprobación es 4
                (cuatro).
              </ListItem>
              <ListItem>
                Final: un examen final es un examen que engloba todos los
                contenidos de una materia. Puede rendirse el final de una
                materia tantas veces sea necesario hasta aprobarlo. Hay
                aproximadamente 4 instancias donde pueden rendirse finales a lo
                largo del año (por ejemplo, en febrero), y esas instancias son
                llamadas “mesas”.
              </ListItem>
            </ListItem>
            <br />

            <ListItem>
              Regularidad y promoción
              <br />
              En primer lugar, es fundamental que entendamos que hay que
              regularizar todas las materias. La diferencia entre regularizarla
              o promocionarla radica en si debemos rendir un examen final (que
              engloba todos los contenidos de la materia) o no. ¿Cómo
              establecemos esa diferencia? Si regularizamos la materia
              (aprobando ambas instancias de parcial durante la cursada con nota
              4 y asistiendo al 75% de clases), debemos rendir final. <br />
              Si además de regularizarla queremos promocionarla, la diferencia
              es que debemos obtener nota 6 o más de promedio en los parciales,
              y en ese caso no debemos rendir final. <br />
              Además, es importante leer los Planes de Trabajo Docente (PTDs)
              para saber si la materia que estamos cursando puede ser
              promocionada o no porque, en algunos casos, se deberá rendir final
              obligatoriamente. En otras palabras, no todas las materias son
              promocionales.
            </ListItem>
          </List>
        </Expandable>
        <Expandable title="Boleto Estudiantil">
          El boleto estudiantil, otorgado por el gobierno de la Provincia de
          Buenos Aires, implica la acreditación mensual por el valor equivalente
          a 45 boletos en nuestras tarjetas SUBE. <br />
          <br />
          ¿Cómo pido el boleto estudiantil? <br />
          Para solicitar el boleto estudiantil tenés que llenar un formulario en
          el SIU Guaraní, a través de la pestaña “Trámites”. Una vez realizado
          este paso, la página te redireccionará para completar otro formulario
          de la Secretaría de Transporte. Una vez realizado el trámite, deberás
          consultar en la pestaña “Trámites” {">"} ”Solicitud Boleto
          Estudiantil” para asegurarte que la Secretaría de Transporte haya
          aprobado tu solicitud. No olvides que la tarjeta SUBE que ingreses en
          el formulario debe estar previamente registrada a tu nombre desde la
          web o aplicación móvil SUBE. <br />
          Una vez aceptado el trámite, la carga se deberá acreditar al paso de
          un mes y medio en un tótem sube o a través de la web o aplicación
          móvil SUBE.
        </Expandable>
        <Expandable title="Otros espacios para lxs estudiantes">
          Comedor Universitario <br /><br />
          El comedor universitario se encuentra a unas pocas cuadras de la facultad (Rodríguez Peña y Funes). Ahí lxs estudiantes podemos acceder a un menú de almuerzo y/o merienda subsidiado. Para acceder solo tenes que acercarte con tu DNI. <br />
          Horario de almuerzo: de 11:30hs a 15hs <br /><br />
          Biblioteca <br /><br />
          La biblioteca de la UNMdP se encuentra en la misma manzana que el comedor universitario. Ahí lxs estudiantes podemos ir a estudiar y retirar libros cuando los necesites, haciéndote socio.<br />
          Horario: lunes a viernes de 08:00hs a 20:00hs
        </Expandable>
        <Expandable title="Contactos Útiles">
          Antropología
          <ListItem>
            Mail del departamento: <a href="mailto:{depto.antropologia.unmdp@gmail.com}">depto.antropologia.unmdp@gmail.com</a>
          </ListItem>
          <ListItem>2236902261 (Valen)</ListItem>
          <ListItem>2235139139 (Iara)</ListItem>
          <ListItem>@hxep_antropologia</ListItem>
          <br />
          Ciencias de la Educación
          <br />
          <ListItem>Mail del departamento: <a href="mailto:{pedagohu@mdp.edu.ar}">pedagohu@mdp.edu.ar</a></ListItem>
          <ListItem>2236964282 (Caro)</ListItem>
          <ListItem>2236836480 (Ihan)</ListItem>
          <ListItem>@cce_hxep</ListItem>
          <br />
          Ciencias de la Información
          <br />
          <ListItem>
            Mail del departamento: <a href="mailto:{mardedoc@gmail.com}">mardedoc@gmail.com</a> - <a href="mailto:{coordistancia@gmail.com}">coordistancia@gmail.com</a>
            (Biblio a distancia)
          </ListItem>
          <ListItem>2236351293 (Juli)</ListItem>
          <ListItem>@hxepbiblio</ListItem>
          <br />
          Ciencia Política
          <br />
          <ListItem>Mail del departamento: <a href="mailto:{cpolitica@mdp.edu.ar}">cpolitica@mdp.edu.ar</a></ListItem>
          <ListItem>2236323240 (Dai)</ListItem>
          <ListItem>2235331771 (Lucho)</ListItem>
          <ListItem>2236021623 (Cami)</ListItem>
          <ListItem>@lasimonbolivarunmdp</ListItem>
          <br />
          Filosofía
          <br />
          <ListItem>Mail del departamento: <a href="mailto:{filohum@mdp.edu.ar}">filohum@mdp.edu.ar</a></ListItem>
          <ListItem>2291416350 (Juani)</ListItem>
          <ListItem>2236925531 (Pri)</ListItem>
          <ListItem>@hxepfilo</ListItem>
          <br />
          Geografía
          <br />
          <ListItem>Mail del departamento: <a href="mailto:{geofhum@mdp.edu.ar}">geofhum@mdp.edu.ar</a></ListItem>
          <ListItem>2235457203 (Ro)</ListItem>
          <ListItem>@hxepgeo</ListItem>
          <br />
          Historia
          <br />
          <ListItem> Mail del departamento: <a href="mailto:{historia@mdp.edu.ar}">historia@mdp.edu.ar</a></ListItem>
          <ListItem> 2235036565 (Azu)</ListItem>
          <ListItem>3814069682 (Facu)</ListItem>
          <ListItem> @hermeneutica.unmdp</ListItem>
          <br />
          Lenguas Modernas
          <br />
          <ListItem>Mail del departamento: <a href="mailto:{modernas@mdp.edu.ar}">modernas@mdp.edu.ar</a></ListItem>
          <ListItem>2234234254 (Cande)</ListItem>
          <ListItem>2235863465 (Iara)</ListItem>
          <br />
          Lengua Italiana
          <br />
          <ListItem>
            Mail del departamento: <a href="mailto:{departamentoitalianofh@gmail.com}">departamentoitalianofh@gmail.com</a>
          </ListItem>
          <ListItem>2234481038 (Lu)</ListItem>
          <ListItem>2236228398 (Cata)</ListItem>
          <ListItem>@hxepitaliano</ListItem>
          <br />
          Letras
          <br />
          <ListItem>Mail del departamento: <a href="mailto:{letras@mdp.edu.ar}">letras@mdp.edu.ar</a></ListItem>
          <ListItem>2236019085 (Luna)</ListItem>
          <ListItem>2235050458 (Cris)</ListItem>
          <ListItem>@hxepletras</ListItem>
          <br />
          Sociología
          <br />
          <ListItem>Mail del departamento: <a href="mailto:{depsocio@mdp.edu.ar}">depsocio@mdp.edu.ar</a></ListItem>
          <ListItem>2235450697 (Fer)</ListItem>
          <ListItem>2235903732 (Barbi)</ListItem>
          <ListItem>@deconstruyendosocio</ListItem>
          <br />
          Oficinas y Secretarías
          <br />
          <ListItem>Secretaría Académica: <a href="mailto:{academh@mdp.edu.ar}">academh@mdp.edu.ar</a></ListItem>
          <ListItem>Área de Asuntos Estudiantiles: <a href="mailto:{asuntosestudiantiles.fhunmdp@gmail.com}">asuntosestudiantiles.fhunmdp@gmail.com</a></ListItem>
          <ListItem>División alumnos: <a href="mailto:{alumnosh@hotmail.com}">alumnosh@hotmail.com</a></ListItem>
          <ListItem>Servicio social: <a href="mailto:{ssu.huma@gmail.com}">ssu.huma@gmail.com</a></ListItem>
          <ListItem>Secretaría de coordinación: <a href="mailto:{coordina@mdp.edu.ar}">coordina@mdp.edu.ar</a></ListItem>
          <ListItem>Secretaría de extensión y bienestar: <a href="mailto:{extensionybienestarestudiantil.fh@mdp.edu.ar}">extensionybienestarestudiantil.fh@mdp.edu.ar</a></ListItem>
          <ListItem>Concurso: <a href="mailto:{concuhuma@mdp.edu.ar}">concuhuma@mdp.edu.ar</a></ListItem>
          <ListItem>Mesa de entradas: <a href="mailto:{mesaentradahum@mdp.edu.ar}">mesaentradahum@mdp.edu.ar</a></ListItem>
        </Expandable>
      </Card>
    </>
  );
};

const List = styled.ul`
  list-style-type: unset;
`;

const ListItem = styled.li`
  margin: 12px 0 12px 32px;
  line-height: 20px;
`;

const FaqImg = styled.img`
  width: 100%;
  margin-top: 12px;
  border-radius: ${(p) => p.theme.radius.medium};
`;