
import BackgroundImage0 from "../../app/images/ambiente-background.png";
import BackgroundImage1 from "../../app/images/articulacionTerritorial-background.png";
import BackgroundImage2 from "../../app/images/asuntosAcademicos-background.png";
import BackgroundImage3 from "../../app/images/deportes-background.png";
import BackgroundImage4 from "../../app/images/genero-background.png";
import BackgroundImage5 from "../../app/images/salud-background.png";
import AuxImage from "../../app/images/deportes-aux.jpg";
import Image from "../../components/Image";
import { Background } from "../../components/Background";
import { useTitle } from "../../app/hooks/useTitle";
import { Card } from "../../components/Card";
import { Column, Row } from "../../components/Flex";
import { Tab } from "../../components/Tab";
import { useState } from "react";
import { Divider } from "../../components/Divider";
import { Paragraph, Subtitle, Title } from "../../components/Text";
import { SuperTitle } from "../../components/SuperTitle";
import { Icon, icons } from "../../components/Icon";
import { GeneroCard } from "./GeneroCard";
import { ConquistasCard } from "./ConquistasCard";
import { SocialMedia0 } from "./SocialMedia0";
import { SocialMedia1 } from "./SocialMedia1";
import { SocialMedia3 } from "./SocialMedia3";
import { SocialMedia4 } from "./SocialMedia4";
import { SocialMedia5 } from "./SocialMedia5";
import { SECRETARIAS } from "../../app/api";
import { Link } from "../../components/Link";

export const Secretarias = () => {
  useTitle("HxEP - Secretarias");
  const [currentSecretaria, setCurrentSecretaria] = useState(SECRETARIAS[0]);

  const secretarias = {
    [SECRETARIAS[0].id]: (
      <>
        <Title>Secretaría de Ambiente y Hábitat</Title>
        <Paragraph>
          La Secretaría de Ambiente y Hábitat es un espacio gremial del Centro
          de Estudiantes de Humanidades que tiene el objetivo de fomentar
          actividades y espacios de debate político en lo que respecta a la
          sustentabilidad ambiental. Desde su creación en 2022, llevamos
          adelante distintas instancias como charlas y actividades para repensar
          y problematizar el uso del espacio que le damos a la Facultad de
          Humanidades y sus alrededores, construyendo una facultad atravesada
          por la perspectiva ambiental.
        </Paragraph>
        <Paragraph>
          Desde Humanidades por el Proyecto creemos que las políticas de índole
          ambiental en nuestra alta casa de estudios y en nuestra ciudad son
          fundamentales para construir de manera colectiva nuestro recorrido
          académico entendiendo a la universidad pública como parte de un país
          que tiende a desarrollarse soberanamente. Como estudiantxs
          universitarixs, necesitamos políticas ambientales reales que
          atraviesen distintas problemáticas que sufrimos diariamente en
          nuestros espacios. Desde la Secretaría de Ambiente y Hábitat creemos
          en la necesidad de construir esa agenda con la participación de todxs
          los estudiantes de Humanidades.
        </Paragraph>

        <Subtitle>Contacto</Subtitle>
        <Paragraph>
          <Row gap="8px">
            <Icon as={icons.phone} size="16px" />
            Cristina: 2235050458
            <br />
            <br />
            More: 2494025931 <br />
            <br />
          </Row>
          <Link
            href="https://instagram.com/ambienteyhabitat.fh/"
            accent
            target="_blank"
          >
            Instagram
          </Link>
        </Paragraph>
      </>
    ),
    [SECRETARIAS[1].id]: (
      <>
        <Title>Secretaría de Articulación Territorial</Title>
        <Paragraph>
          La secretaría de articulación territorial del CEH es un espacio
          destinado al diálogo entre lxs estudiantes y los barrios de nuestra
          región. Creemos que las tareas de extensión son una parte fundamental
          de nuestra universidad y desde esta secretaría fomentamos esos lazos
          de construcción entre lxs estudiantes de la facultad de humanidades y
          las distintas instituciones y organismos que nos rodean.
        </Paragraph>

        <Paragraph>
          El sentido de nuestro trabajo se propone en una línea de solidaridad
          generando y acercando propuestas que tengan que ver con construir
          puentes con la comunidad. Desde la secretaría coordinamos encuentros
          de apoyo escolar, colectas de ropa y distintas actividades a lo largo
          del año. Construimos y pensamos estas iniciativas convencidxs de que
          poner nuestra formación en función del pueblo es una misión ineludible
          para aquellxs que transitamos el camino universitario orgullosxs del
          sistema público.
        </Paragraph>
        <Subtitle>Contacto</Subtitle>
        <Paragraph>
          <Row gap="8px">
            <Icon as={icons.phone} size="16px" />
            Fermin 2214358636
            <br />
            <br />
            Eric 2236970949 <br />
            <br />
          </Row>
          <Link
            href="https://instagram.com/articulacion_territorial.fh/"
            accent
            target="_blank"
          >
            Instagram
          </Link>
        </Paragraph>
      </>
    ),
    [SECRETARIAS[2].id]: (
      <>
        <Title>Secretaría de Asuntos Académicos</Title>
        <Paragraph>
          La Secretaría de Asuntos Académicos del Centro de Estudiantes busca
          ser un nexo entre lxs estudiantxs y el Consejo Académico de la
          Facultad de Humanidades. Desde Humanidades por el Proyecto a partir
          del 2016 comenzamos a participar en el Consejo Académico. En ese
          espacio impulsamos y acompañamos distintas políticas orientadas al
          ingreso, la permanencia y el egreso de lxs estudiantes en nuestra
          Facultad: presentamos y aprobamos en el año 2016 el primer reglamento
          de cursada con perspectiva estudiantil que fue modificandose a lo
          largo de los años sumando nuevas consideraciones necesarias para
          nuestras trayectorias; en el año 2023 impulsamos el Sistema de
          Licencias Estudiantiles que contiene herramientas fundamentales para
          continuar nuestras cursadas ante cualquier eventualidad.
        </Paragraph>
        <Paragraph>
          El Consejo Académico como espacio de discusión y diálogo interclaustro
          es, desde el 2016, un canal para la ampliación y defensa de nuestros
          derechos conquistados en la Universidad Pública.
        </Paragraph>
        <Subtitle>Contacto</Subtitle>
        <Paragraph>
          <Row gap="8px">
            <Icon as={icons.phone} size="16px" />
            Gabriel: 223 603 0346
            <br />
            <br />
            Shai: 223 303 0443 <br />
            <br />
          </Row>
        </Paragraph>
      </>
    ),
    [SECRETARIAS[3].id]: (
      <>
        <Title>Secretaría de Deportes</Title>
        <Paragraph>
          La Secretaría de Deportes del CEH es un espacio gremial abierto que
          entiende al deporte como una herramienta de inclusión social y como un
          recurso para la salud física y mental, que debe estar atravesado
          también por una perspectiva de género.{" "}
        </Paragraph>
        <Paragraph>
          Desde Humanidades por el Proyecto, entendemos que el acceso al deporte
          contribuye a la permanencia en nuestras trayectorias educativas,
          construyendo nuevos espacios de participación y socialización
          colectivos, generando hábitos saludables en el trayecto por la
          Universidad Pública.{" "}
        </Paragraph>
        <Paragraph>
          En este marco, mediante la Secretaría de Deportes buscamos fomentar
          instancias de participación deportiva entre estudiantes ya que creemos
          que las actividades recreativas practicadas entre compañerxs
          fortalecen los vínculos mientras se promueve la salud y el bienestar
          de la comunidad de humanidades. La oferta deportiva brindada por la
          UNMDP de manera gratuita es variada, ¡Acércate a practicar el deporte
          que más te guste!{" "}
        </Paragraph>
        <Paragraph>
          Acá podes acceder a esta información: {/* Falta link */}{" "}
        </Paragraph>
        <Subtitle>Licencia Especial Deportiva:</Subtitle>
        <Paragraph>
          Desde la Secretaría de Deportes del Centro de Estudiantes de
          Humanidades trabajamos en conjunto con la Secretaría de Extensión y
          Bienestar estudiantil para que se contemple la participación deportiva
          de estudiantes federados e integrantes de delegaciones deportivas,
          agregando esta causal al sistema de licencias en el año 2022.
        </Paragraph>
        <Paragraph>
          Para solicitar la licencia especial deportiva se solicita el
          certificado expedido por el Ente Nacional de Alto Rendimiento (ENARD)
          o el certificado expedido por la federación deportiva de la actividad
          que realice lx estudiante. En ambos casos debe constatarse la fecha de
          inicio y finalización de la actividad así como la localidad en donde
          se desarrollará.
        </Paragraph>
        <Paragraph>
          Desde Humanidades por el Proyecto creemos que la participación de lxs
          estudiantes en actividades deportivas de alto rendimiento que implican
          representar a nuestro país o a clubes de nuestra ciudad no deberían
          poner en riesgo la continuidad académica de nuestrxs compañerxs. Si
          tenés alguna duda de cómo tramitar la licencia, contactate con
          nuestrxs compañeros!
        </Paragraph>
        <Subtitle>Oferta Deportiva:</Subtitle>
        {/* Falta JPG */}
        <Subtitle>Contacto</Subtitle>
        <Paragraph>
          <Row gap="8px">
            <Icon as={icons.phone} size="16px" />
            Cami: 2236021623
            <br />
            <br />
            Facu: 3814069682 <br /><br />
          </Row>
          <Link
            href="https://instagram.com/secretariadeportes.ceh/"
            accent
            target="_blank"
          >
            Instagram
          </Link>
        </Paragraph>
      </>
    ),
    [SECRETARIAS[4].id]: (
      <>
        <GeneroCard />
        <ConquistasCard />
      </>
    ),
    [SECRETARIAS[5].id]: (
      <>
        <Title>Secretaría de Salud</Title>
        <Paragraph>
          La secretaría de salud fue creada a fines de 2023 con la finalidad de
          generar instancias y promover políticas gremiales vinculadas con la
          salud y el autocuidado de lxs estudiantes de Humanidades a lo largo de
          su trayectoria por la Universidad Pública.
        </Paragraph>

        <Paragraph>
          Teniendo en cuenta el difícil contexto que atravesamos no sólo como
          actorxs de la comunidad universitaria, sino como ciudadanxs argentinxs
          en torno al acceso al derecho a la salud, uno de los principales
          objetivos de la Secretaría de Salud del CEH se relaciona con llevar a
          cabo políticas gremiales relacionadas a la salud mental, física y
          sexual de todxs lxs estudiantes garantizando así el acceso a la misma.
        </Paragraph>
        <Paragraph></Paragraph>
        <Subtitle>Contacto</Subtitle>
        <Paragraph>
          <Row gap="8px">
            <Icon as={icons.phone} size="16px" />
            Valen: 2236902261
            <br />
            <br />
            More: 2236796666 <br /><br />
          </Row>
          <Link
            href="https://instagram.com/secretariadesalud.ceh/"
            accent
            target="_blank"
          >
            Instagram
          </Link>

        </Paragraph>
      </>
    ),
  };

  return (
    <>
      <SuperTitle>Secretarías</SuperTitle>
      {currentSecretaria.id === SECRETARIAS[0].id && <SocialMedia0 />}
      {currentSecretaria.id === SECRETARIAS[1].id && <SocialMedia1 />}
      {currentSecretaria.id === SECRETARIAS[3].id && <SocialMedia3 />}
      {currentSecretaria.id === SECRETARIAS[4].id && <SocialMedia4 />}
      {currentSecretaria.id === SECRETARIAS[5].id && <SocialMedia5 />}
      {currentSecretaria.id === SECRETARIAS[0].id && (
        <Background
          src={BackgroundImage0}
          alt="Secretarias - Humanidades x El Proyecto"
        />
      )}
      {currentSecretaria.id === SECRETARIAS[1].id && (
        <Background
          src={BackgroundImage1}
          alt="Secretarias - Humanidades x El Proyecto"
        />
      )}
      {currentSecretaria.id === SECRETARIAS[2].id && (
        <Background
          src={BackgroundImage2}
          alt="Secretarias - Humanidades x El Proyecto"
        />
      )}
      {currentSecretaria.id === SECRETARIAS[3].id && (
        <Background
          src={BackgroundImage3}
          alt="Secretarias - Humanidades x El Proyecto"
        />
      )}
      {currentSecretaria.id === SECRETARIAS[4].id && (
        <Background
          src={BackgroundImage4}
          alt="Secretarias - Humanidades x El Proyecto"
        />
      )}
      {currentSecretaria.id === SECRETARIAS[5].id && (
        <Background
          src={BackgroundImage5}
          alt="Secretarias - Humanidades x El Proyecto"
        />
      )}
      <Card>
        <Row flexWrap="wrap" justifyContent="center">
          {SECRETARIAS.map((secretaria) => (
            <Tab
              selected={secretaria.id === currentSecretaria.id}
              key={secretaria.id}
              onClick={() => setCurrentSecretaria(secretaria)}
            >
              {secretaria.name}
            </Tab>
          ))}
        </Row>
        <Divider />
        {secretarias[currentSecretaria.id]}
        {currentSecretaria.id === SECRETARIAS[3].id && (
          <Column gap="0">
            <Divider style={{ marginBottom: "32px" }} />
            <Image
              src={AuxImage}
              alt="Deportes - Humanidades x El Proyecto"
            ></Image>
          </Column>
        )}
        {currentSecretaria.id === SECRETARIAS[4].id && (
          <Column gap="0">
            <Divider style={{ marginBottom: "32px" }} />
            <a
              target="-blank"
              href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ETaluio_2r5EvZh8eLYrLToB-au7ln3SGqyiIdG1BqeUag?e=1HJOXV"
            >
              <Subtitle>
                <Icon as={icons.file} />
                Guía de Lenguaje Inclusivo
              </Subtitle>
            </a>
            {/*<FileSystemOneDrive rootFolderId={currentSecretaria.id} breadcrum={false} />*/}
            <br />
            <a
              target="-blank"
              href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EeE_fNx8J_JMsztTfcaMldwBM9ot1YD5AogLk00xB9PjmQ?e=EOQX8q"
            >
              <Subtitle>
                <Icon as={icons.file} />
                Guía para la Activación del Protocolo de Género de la UNMDP
              </Subtitle>
            </a>
            {/*<FileSystemOneDrive rootFolderId={currentSecretaria.id} breadcrum={false} />*/}
          </Column>
        )}
      </Card>
    </>
  );
};
