import styled from "styled-components";
import { WelcomeCard } from "./WelcomeCard";
import { Space } from "../../components/Space";
import { CursadasSolidadariasCard } from "./CursadasSolidariasCard";
import { OrganizaCard } from "./OrganizaCard";
import { Background } from "../../components/Background";
import BackgroundImage from "../../app/images/home-background.jpg";
import LogoHumanidades from "../../app/images/logo.png";
import { useTitle } from "../../app/hooks/useTitle";
import { SocialMedia } from "./SocialMedia";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


export const Home = () => {
  const hash = useLocation().hash;
  useTitle("HxEP - Inicio");

  useEffect(() => {
    const estatutos = document.getElementById("estatutos");
    const reglamentos = document.getElementById("reglamentos");
    if (hash === "#estatutos") {
      estatutos.scrollIntoView();
    } else if (hash === "#reglamentos") {
      reglamentos.scrollIntoView();
    }
  }, [hash]);

  return (
    <>
      <Logo src={LogoHumanidades} alt='Logo Humanidades x El Proyecto' />
      <SocialMedia />
      <Background src={BackgroundImage} alt='Humanidades x El Proyecto' />
      <Space height={`55vh`} maxHeight='720px' />
      <WelcomeCard />
      <CursadasSolidadariasCard />
      <OrganizaCard />
    </>
  );
};

const Logo = styled.img`
  width: 350px;
  position: absolute;
`;
