import BackgroundImage from "../../app/images/home-background.jpg";
import { SuperTitle } from "../../components/SuperTitle";
import { Background } from "../../components/Background";
import { Card } from "../../components/Card";
import { Divider } from "../../components/Divider";
import { Column } from "../../components/Flex";
import { Paragraph, Subtitle } from "../../components/Text";
import { Icon, icons } from "../../components/Icon";

export const Normativas = () => {
  return (
    <>
      <SuperTitle>Normativas</SuperTitle>
      <Background
        src={BackgroundImage}
        alt="Carreras - Humanidades x El Proyecto"
      />
      <Card>
        <Paragraph>
          Te compartimos distintas normativas que hacen al funcionamiento de
          nuestro Centro de Estudiantes, nuestra Facultad y nuestra Universidad,
          como reglamentos y estatutos. Podés encontrar el reglamento de cursada
          o los reglamentos de adscripciones y ayudantías estudiantiles. También
          el Estatuto de la Universidad y el Estatuto del Centro de Estudiantes
          de Humanidades, renovado en el año 2022, siendo el anterior de 1983.
          Desde Humanidades por el Proyecto creíamos necesario adecuarlo a las
          nuevas realidades de lxs estudiantes y del mundo social, político y
          cultural que nos rodea. Desde el 2022 tenemos un nuevo Estatuto
          actualizado e innovador, que retoma las discusiones que hoy tenemos
          lxs estudiantes y que reconoce prácticas y dinámicas ya afianzadas en
          nuestro gremio. <br /> ¡Cualquier consulta sobre reglamentaciones o
          normativas, escribinos!
        </Paragraph>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/Eek1kWz7NWhLt5PX8JkCv2IBHY-3ydYZCuFiJ-h9RExpNQ?e=BKY9rw">
            <Subtitle>
              <Icon as={icons.file} />
              Estatuto UNMDP
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive  breadcrum={false} />*/}
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ETh-6ElrzE5IjZsOKyWuRG4BXnhfNIZhYUfOoCFjeB54sg?e=cTmtqp">
            <Subtitle>
              <Icon as={icons.file} />
              Estatuto CEH
            </Subtitle>
          </a>
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:w:/g/personal/juancristini_infohuma_onmicrosoft_com/EfNK7eQBDYBOlfxT9B10GdsBvKg-pTRRxGcIHMNV2k9Txg?e=mQn7Eh">
            <Subtitle>
              <Icon as={icons.file} />
              Reglamento de Cursada
            </Subtitle>
          </a>
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EaF-atpqGmtMvF5PuNIjuMkBm4KWxgMrcddcn4R1XYc8ng?e=bu8zU4">
            <Subtitle>
              <Icon as={icons.file} />
              Reglamento Ayudante Estudiante
            </Subtitle>
          </a>
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/Ef-Fiz47IERNm2eCAgOb-XIB65K5QWTrNGvDnrmS--NwxQ?e=fPuE3A">
            <Subtitle>
              <Icon as={icons.file} />
              Reglamento Adscripciones
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive  breadcrum={false} />*/}
        </Column>
      </Card>
    </>
  );
};
