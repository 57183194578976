import styled from "styled-components";
import { addProp } from "../app/util";

export const AltButton = ({ children, text, textMaxWidth, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {children}
      <TextContainer textMaxWidth={textMaxWidth}>{text}</TextContainer>
      <Tooltip>{text}</Tooltip>
    </Wrapper>
  );
};

const Tooltip = styled.span`
  --main-color: #dadada;
  visibility: hidden;
  background-color: white;
  color: #7e7e7e;
  border: 1px solid var(--main-color);
  text-align: center;
  padding: 6px 8px;
  border-radius: ${p => p.theme.radius.small};
  position: absolute;
  line-height: 1.4;
  font-size: 14px;
  width: unset;
  top: 125%;
  left: 50%;
  margin-left: -50%;

  &::after {
    z-index: 100;
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 20px;
    margin-left: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent var(--main-color) transparent;
  }
`;

const Wrapper = styled.span`
  background-color: ${p => p.theme.color.light};
  border-radius: ${p => p.theme.radius.small};
  padding: 9px 12px;
  user-select: none;
  cursor: pointer;
  position: relative;
  max-width: ${p => 250 - p.itemCount * 32}px;
  display: flex;
  gap: 8px;
  align-items: center;
  z-index: 2;

  &:hover {
    background-color: ${p => p.theme.color.light}9f;
    z-index: 4;

    ${Tooltip} {
      visibility: visible;
    }
  }
`;

const TextContainer = styled.span`
  width: 100%;
  ${p => addProp("max-width", p.textMaxWidth)}
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${p => p.theme.mq.small} {
    ${p => addProp("max-width", "220px")}
  }

`;
