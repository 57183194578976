import BackgroundImage from "../../app/images/home-background.jpg";
import { SuperTitle } from "../../components/SuperTitle";
import { Background } from "../../components/Background";
import { Card } from "../../components/Card";
import { Row } from "../../components/Flex";
import { Divider } from "../../components/Divider";
import { Column } from "../../components/Flex";
import { Paragraph, Subtitle, Title } from "../../components/Text";
import { Icon, icons } from "../../components/Icon";



export const Derechos = () => {

  return (
    <>
      <SuperTitle>Derechos de Cursada</SuperTitle>
      <Background src={BackgroundImage} alt='Carreras - Humanidades x El Proyecto' />
      <Card>
        <Paragraph>
          Desde Humanidades por el Proyecto participamos hace varios años de las instancias de cogobierno de nuestra facultad. Construimos cada espacio para llenarlos de perspectiva estudiantil y de ampliación de derechos. Durante el 2016 creamos la base del  primer reglamento de cursada con perspectiva estudiantil de nuestra facultad que al día de hoy sigue vigente, con diversas ampliaciones y modificaciones que se agregaron a lo largo de los años. Durante el 2023 trabajamos articuladamente con la Secretaría de Extensión y Bienestar de la Facultad de Humanidades en la construcción de un proyecto de licencias estudiantiles, creando el Sistema de Licencias estudiantiles.
        </Paragraph>
        <Title>
          Conocé nuestros derechos de cursada
        </Title>
        <Subtitle>
          Asignaturas regulares (con final obligatorio)
        </Subtitle>
        <Paragraph>
          Para aprobar la materia y obtener la regularidad se deberá asistir en forma obligatoria a un porcentaje no inferior a 75% de las clases prácticas y aprobar el 75% de los trabajos prácticos de la materia. También deberán aprobar 2 exámenes parciales con nota no inferior a 4 (cuatro) puntos, con derecho a sus dos correspondientes recuperatorios.
        </Paragraph>
        <Paragraph>
          Asistencia: en materias regulares no es obligatoria la asistencia a las clases teóricas, sí a las prácticas. 
        </Paragraph>
        <Paragraph>
          Exámen final: quienes hayan obtenido la condición de regular al finalizar la cursada podrán rendir el exámen final, con el programa que cursaron en un plazo no mayor a 30 meses. Finalizado ese plazo, deberán rendir el exámen final con el programa vigente.
        </Paragraph>
        <Subtitle>
          Asignaturas promocionales
        </Subtitle>
        <Paragraph>
          Para lograr la promoción en las asignaturas de régimen promocional deberán asistir en forma obligatoria, a un porcentaje no inferior al 75% de la totalidad de las clases. Además deberán aprobar el 75% de los trabajos prácticos establecidos en la materia y 2 exámenes parciales o sus correspondientes recuperatorios con un promedio no inferior a 6 puntos. 
        </Paragraph>
        <Paragraph>
          Asistencia: es obligatorio asistir a las clases tanto prácticas como teóricas.         </Paragraph>
        <Paragraph>
          Nota final: para la nota final deberá promediarse la nota obtenida en los exámenes parciales o recuperatorios, y/o prácticos aprobados, redondeando los valores con centésimos (menos de 50 centésimos corresponde la nota inferior, 50 centésimos o más corresponde la nota superior).
        </Paragraph>
        <Subtitle>
        Fechas de parciales
        </Subtitle>
        <Paragraph>
          Las fechas deberán comunicarse al menos con 15 días de antelación. Los parciales y recuperatorios deberán rendirse en los horarios regulares de cada asignatura.
        </Paragraph>
        <Paragraph>
          Deberá existir un plazo de 48hs entre parciales de materias del mismo año y del mismo departamento.
        </Paragraph>
        <Subtitle>
          Recuperatorios
        </Subtitle>
        <Paragraph>
          Los recuperatorios deberán tomarse en un plazo mínimo de 7 días después de la comunicación de las notas de los respectivos parciales.
        </Paragraph>
        <Paragraph>
          El contenido temático de los recuperatorios debe ser el mismo que el parcial, no se pueden agregar temas.
        </Paragraph>
        <Subtitle>
         Asistencia
        </Subtitle>
        <Paragraph>
          La asistencia es obligatoria para las clases teóricas o prácticas en el caso de materias promocionales y solo para clases prácticas en asignaturas regulares. Se admite un 25% de inasistencias.
        </Paragraph>

        <Title>
          Sistema de Licencias Estudiantiles
        </Title>
        <Paragraph>
        Podés pedir una Licencia estudiantil o un Certificado Único de licencia estudiantil por diversas causales.
        </Paragraph>
        <Subtitle>
        Licencias estudiantiles
        </Subtitle>
        <Paragraph>
        - Nacimiento y responsabilidad parental.
        <br />
        - Adopción o guarda pre-adoptiva con fines de adopción.
        <br />
        - Salud.
        <br />
        - Intervenciones quirúrgicas.
        <br />
        - Fallecimiento de familias, conyuge y/o conviviente.
        <br />
        - Tareas de cuidado o persona a cargo.
        <br />
        - Situaciones judiciales específicas.
        <br />
        - Violencia de género.
        <br />
        - Participación deportiva.
        <br />
        - Participación en actividades académicas, científicas o culturales.
        <br />
        - Actividades de intercambio en universidades o instituciones en el extranjero.
        <br />
        - Capacitaciones concernientes al área curricular afin.
        <br />
        - Representación de claustro.
        <br />
        - Asambleas estudiantiles ordinarias y anuales.
        <br />
        <br />
        Las licencias estudiantiles reconocen un 15% de inasistencias justificadas sobre el total de las clases y permite reprogramar las instancias de evaluación (TPs y parciales) en las fechas solicitadas.
        </Paragraph>
        <Subtitle>
        Certificado Único
        </Subtitle>
        <Paragraph>
        - Emabarazo.
        <br />
        - Enfermedades crónicas.
        <br />
        - Discapacidad.
        <br />
        - Salud mental.
        <br />
        - Situaciones de violencia de género.
        <br />
        - Tareas de cuidado o persona a cargo.
        <br />
        <br />
        El certificado único facilita el mecanismo de presentación de solicitudes de licencias estudiantiles para los casos en que la situacion que impacta en el recorrido académico tenga un diagnóstico crónico y/o persista en un tiempo indeterminado. 
        </Paragraph>
        <Subtitle>
        ¿Cómo los solicito?
        </Subtitle>
        <Paragraph>
        Enviar por mesa de entradas en sobre cerrado con el asunto “solicitud de licencia estudiantil”, al Área de asuntos estudiantiles:
        <br />
        <br />
        - Formulario de solicitud de licencia estudiantil (podes pedir modelo en el CEH).
        <br />
        - Copia de DNI (ambos lados).
        <br />
        - Certificado de estudiante regular.
        <br />
        - Certificado correspondiente a la causal.
        <br />
        <br />
        Acercate al CEH y te damos una mano para solicitar tu licencia!
        </Paragraph>

        <Row flexWrap='wrap' justifyContent='center'>
        </Row>
        <Column gap='0'>
          <Divider style={{ marginBottom: "32px" }} />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EWg1WoTNiXVNgx8IuS3PLLkBRbBLPjPZdJL7w_mDmeCFOw?e=H4K2SU">
            <Subtitle>
              <Icon as={icons.file} />
              Reglamento original - Marzo 2016
            </Subtitle>
          </a>
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ES4iEA2RHFlGhqyX6RzDM5kBm_LNotD3WWXR-F4YFR5IfQ?e=MAO4bB">
            <Subtitle>
              <Icon as={icons.file} />
              1era Modificatoria al reglamento - Agosto 2016
            </Subtitle>
          </a>
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EblZGOiZmphPpuhrpuIBSOQB1Sy5YHQ_4tTQ_6ph-qcjvw?e=rCFdXx">
            <Subtitle>
              <Icon as={icons.file} />
              2da Modificatoria al reglamento - Agosto 2019
            </Subtitle>
          </a>
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EeC86jioo0pNvV3jM-0nYHsBYU_s2IU1n7uNNQ3xTA8XEw?e=C8MH7w">
            <Subtitle>
              <Icon as={icons.file} />
              Ordenanza Consejo Académico para Licencias
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive  breadcrum={false} />*/}
          <br />
          <a target="_blank" href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ERgk_a5kggZKhwdsorRGA0cBr0_I_q8HvtBycL2MuBik8g?e=DTN7Zd">
            <Subtitle>
              <Icon as={icons.file} />
              Formulario Licencia Estudiantil
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive  breadcrum={false} />*/}
        </Column>
      </Card>
    </>
  );
};


