import { Column } from "../Flex";
import { Breadcrum } from "../Breadcrum";
import { Divider } from "../Divider";
import { useFileSystemOneDrive } from "./useFileSystemOneDrive";
import { SearchBar } from "../SearchBar";
import { FileSystemContent } from "./FileSystemContent";

export const FileSystemOneDrive = ({ rootFolderId, search, breadcrum = true }) => {
  const { state, goToItem, setSearchStatus } = useFileSystemOneDrive(rootFolderId, search);
  const { breadcrumItems, contentItems, isLoadingContent, serchBarIsEmpty } = state;
  const showBreadcrum = breadcrum && serchBarIsEmpty;

  return (
    <Column gap='8px'>
      {search && <SearchBar onStatusChange={setSearchStatus} />}
      {search && <div />}
      {showBreadcrum && <Breadcrum items={breadcrumItems} onItemClick={goToItem} />}
      {breadcrum && <Divider />}
      <FileSystemContent items={contentItems} isLoading={isLoadingContent} onItemClick={goToItem} />
    </Column>
  );
};
