import styled from "styled-components";
import { useState } from "react";
import { Icon, icons } from "../Icon";
import { Nav } from "./Nav";

import LogoSmall from "../../app/images/logo-small.png";

export const Header = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const toggleMobileMenu = () => setMobileMenuIsOpen(!mobileMenuIsOpen);

  return (
    <Wrapper>
      <Content>
        <MobileContent>
          <a href='/'>
            <Logo src={LogoSmall} alt='Logo HxEP' />
          </a>
          <MobileMenuIcon as={icons.menu} color='white' size='24px' onClick={toggleMobileMenu} />
        </MobileContent>
        <Nav mobileMenuIsOpen={mobileMenuIsOpen} toggleMobileMenu={toggleMobileMenu}/>
      </Content>
      {mobileMenuIsOpen && <ClosingOverlay onClick={toggleMobileMenu} />}
    </Wrapper>
  );
};

const Wrapper = styled.header`
  background-color: ${p => p.theme.color.primary}CD;
  backdrop-filter: blur(6px);
  width: 100%;
  position: fixed;
  z-index: 100;
  user-select: none;
`;

const MobileContent = styled.div`
  display: flex;
  align-self: center;
  justify-content: start;

  ${p => p.theme.mq.large} {
    align-self: stretch;
    justify-content: space-between;
    padding-bottom: 16px;
  }
`;

const Content = styled.div`
  margin: auto;
  max-width: ${p => p.theme.maxWidth}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  height: ${p => p.theme.headerHeight}px;

  ${p => p.theme.mq.large} {
    padding: 16px 16px 0px 16px;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    height: unset;
  }
`;

const Logo = styled.img`
  height: 30px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }

  ${p => p.theme.mq.large} {
    height: 20px;
  }
`;

const MobileMenuIcon = styled(Icon)`
  margin-left: auto;
  display: none;
  cursor: pointer;

  ${p => p.theme.mq.large} {
    display: inline;
  }
`;

const ClosingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0 0 0 0;
  z-index: 1;
`;
