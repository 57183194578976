import styled from "styled-components";

export const SuperTitle = styled.h1`
  font-weight: 800;
  font-size: 56px;
  letter-spacing: 1px;
  color: ${p => p.theme.color.white};
  margin-top: 80px;

  ${p => p.theme.mq.medium} {
    font-size: 48px; 
  }
`;