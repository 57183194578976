import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { useDidMount } from "../app/hooks/usedidMount";
import { Icon, icons } from "./Icon";

// Status: { value: ["empty", "writingQuery" "ready"], query: "Filosofía"}
export const SearchBar = ({ onStatusChange }) => {
  const didMount = useDidMount();
  const theme = useTheme();
  const [value, setValue] = useState("");
  const timer = useRef();

  useEffect(() => {
    if (didMount) return;
    if (timer.current) clearTimeout(timer.current);
    onStatusChange({ value: value === "" ? "empty" : "writingQuery" });
    timer.current = setTimeout(() => {
      onStatusChange({ value: value === "" ? "empty" : "ready", query: value });
    }, 1000);
    return () => clearTimeout(timer.current);
  }, [value, onStatusChange]);

  const reset = () => setValue("");

  return (
    <Content>
      <Input
        type='text'
        placeholder='Buscar material'
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <Icon
        as={value ? icons.x : icons.search}
        size='20px'
        color={theme.color.accent}
        clickable='true'
        onClick={value ? reset : undefined}
      />
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  border-radius: ${p => p.theme.radius.small};
  background-color: ${p => p.theme.color.light};
`;

const Input = styled.input`
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 8px 0;
  width: 100%;
  border-radius: ${p => p.theme.radius.medium};
  font-family: ${p => p.theme.font[1]};
  font-size: 16px;
`;
