import styled from "styled-components";
import { css } from "styled-components";

export const Button = styled.button`
  border: 0;
  outline: 0;
  height: 32px;
  padding: 0 16px;
  box-shadow: ${p => p.theme.shadow};
  border-radius: ${p => (p.rounded ? "100px" : p.theme.radius.small)};
  cursor: pointer;
  transition: 0.2s;
  font-family: ${p => p.theme.font[1]};
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;

  // Primary
  color: ${p => p.theme.color.white};
  background-color: ${p => p.theme.color.accent};
  &:hover {
    color: ${p => p.theme.color.accent};
    background-color: ${p => p.theme.color.white};
  }

  // Secondary
  ${p =>
    p.variant === "secondary" &&
    css`
      color: ${p => p.theme.color.dark};
      background-color: ${p => p.theme.color.light};
      &:hover {
        color: ${p => p.theme.color.light};
        background-color: ${p => p.theme.color.dark};
      }
    `}

  &:active {
    opacity: 0.4;
  }

`;

export const Button2 = styled.button`
  border: 0;
  outline: 0;
  height: 32px;
  padding: 0 16px;
  box-shadow: ${p => p.theme.shadow};
  border-radius: ${p => (p.rounded ? "100px" : p.theme.radius.small)};
  cursor: pointer;
  transition: 0.2s;
  font-family: ${p => p.theme.font[1]};
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 42%;

  // Primary
  color: ${p => p.theme.color.white};
  background-color: ${p => p.theme.color.accent};
  &:hover {
    color: ${p => p.theme.color.accent};
    background-color: ${p => p.theme.color.white};
  }

  // Secondary
  ${p =>
    p.variant === "secondary" &&
    css`
      color: ${p => p.theme.color.dark};
      background-color: ${p => p.theme.color.light};
      &:hover {
        color: ${p => p.theme.color.light};
        background-color: ${p => p.theme.color.dark};
      }
    `}

  &:active {
    opacity: 0.4;
  }

`;