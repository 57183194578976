import styled from "styled-components";
import { useWindow } from "../../app/hooks/useWindow";
import { breakpoints } from "../../app/theme";
import { Button } from "../Button";
import { Space } from "../Space";
import { NavItem } from "./NavItem";

export const Nav = ({ mobileMenuIsOpen, toggleMobileMenu }) => {
  const window = useWindow();

  return (
    <Wrapper mobileMenuIsOpen={mobileMenuIsOpen}>
      <NavList>
        <NavItem to='/carreras' arrowRight>
          Carreras
        </NavItem>
        <VerticalDivider />
        <NavItem to='/secretarias' arrowRight>
          Secretarías
        </NavItem>
        <VerticalDivider />
        <NavItem to='/derechos' arrowRight>
          Derechos de cursada
        </NavItem>
        <VerticalDivider />
        <NavItem to='/espacios' arrowRight onClick={toggleMobileMenu}>
          Espacios de lxs Est.
        </NavItem>
        <VerticalDivider />
        <NavItem to='/normativas' arrowRight onClick={toggleMobileMenu}>
          Normativas
        </NavItem>
        <VerticalDivider />
        <NavItem to='/preguntas-frecuentes' arrowRight>
          Preg. Frec.
        </NavItem>
        <Space width='0px' />
        {window.width <= breakpoints.large ? (
          <NavItem to='/pdf-humanidades' /*target="_blank" href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/Epjn_-lavDBBqEFI27ToKS8BAHj13CmJrrsRB_tepUFTrg?e=8AUKji"*/ arrowRight>
            PDF Humanidades
          </NavItem>
        ) : (
          <NavItem to='/pdf-humanidades' /*target="_blank" href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/Epjn_-lavDBBqEFI27ToKS8BAHj13CmJrrsRB_tepUFTrg?e=8AUKji"*/ notHover>
            <Button>PDF Humanidades</Button>
          </NavItem>
        )}
      </NavList>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  ${p => p.theme.mq.large} {
    ${p => !p.mobileMenuIsOpen && "display: none;"}
  }
`;

const NavList = styled.ul`
  display: flex;
  align-items: center;
  gap: 14px;

  ${p => p.theme.mq.large} {
    gap: 0;
    flex-direction: column;
    align-items: stretch;
  }
`;

const VerticalDivider = styled.div`
  background-color: white;
  height: 30px;
  width: 1px;
  opacity: 15%;

  ${p => p.theme.mq.large} {
    display: none;
  }
`;
