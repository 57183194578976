import { Button } from "../components/Button";
import { Subtitle, Title } from "../components/Text";

export const Error404 = () => {
  return (
    <>
      <Title>Algo salió mal</Title>
      <Subtitle>No encontramos la página que estas buscando</Subtitle>
      <a href='/'>
        <Button>Volver a inicio</Button>
      </a>
    </>
  );
};
