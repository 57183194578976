import styled from "styled-components";
import { Button } from "../../components/Button";
import { Icon, icons } from "../../components/Icon";

export const SocialMedia = () => {
  return (
    <Wrapper>
      <a href='https://www.facebook.com/hxep.unmdp/' target='_blank' rel='noreferrer'>
        <Button variant='secondary' rounded>
          hxep.unmdp
          <Icon as={icons.facebook} size='20px' />
        </Button>
      </a>
      <a href='https://www.instagram.com/hxep_unmdp/' target='_blank' rel='noreferrer'>
        <Button variant='secondary' rounded>
          @hxep_unmdp
          <Icon as={icons.instagram} size='20px' />
        </Button>
      </a>
      <a href='https://twitter.com/hxep_ok/' target='_blank' rel='noreferrer'>
        <Button variant='secondary' rounded>
          @hxep_ok
          <Icon as={icons.twitter} size='20px' />
        </Button>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  position: absolute;
  right: 48px;

  ${p => p.theme.mq.medium} {
    align-items: center;
    right: unset;
    top: 248px;
    transform: scale(1.15);
  }

`;
