import axios from "axios";

const HOST = "https://humanidadesapi-xekg.onrender.com";

export const ESTATUTOS_FOLDER_ID = "34167464EFF01AAB!40996";
export const REGLAMENTOS_FOLDER_ID = "01SVIMBJ3DPAS6C3SY6FFYEH6JR7HPJLT3";
export const CAREER_FOLDER_ID = "34167464EFF01AAB!41003";
export const PDF_HUMANIDADES_FOLDER_ID = "01SVIMBJ4Y4776SWV4GBA2QQKI3O2OQKJP";
export const CAREERS = [
  { id:"54321", name:"Antropología"},
  { id: "34167464EFF01AAB!42817", name: "Ciencia Política" },
  { id: "34167464EFF01AAB!42834", name: "Cs. de la Educación" },
  { id: "34167464EFF01AAB!42854", name: "Cs. de la Información" },
  { id: "34167464EFF01AAB!42773", name: "Filosofía" },
  { id: "34167464EFF01AAB!42861", name: "Geografía" },
  { id: "34167464EFF01AAB!42693", name: "Historia" },
  { id:"12345", name:"Italiano"},
  { id: "34167464EFF01AAB!42667", name: "Lenguas Modernas" },
  { id: "34167464EFF01AAB!42743", name: "Letras" },
  { id: "34167464EFF01AAB!42721", name: "Sociología" }
];
export const SECRETARIAS = [
  {name: "Ambiente y Hábitat", id:"4"},
  {name: "Articulación Territorial", id:"5"},
  {name: "Asuntos Académicos", id:"6"},
  {name: "Deportes", id:"1"},
  {name: "Géneros y Disidencias", id:"3"},
  {name: "Salud", id:"2"},
]

export const getItem = async (id) => {
  const res = await axios.get(`${HOST}/items/${id}`);
  return res.data;
};

export const getItemChildren = async (id) => {
  const res = await axios.get(`${HOST}/items/${id}/children`);
  return res.data;
};

export const getSearchResults = async (id, query) => {
  const res = await axios.get(`${HOST}/search?q=${query}&itemId=${id}`);
  const items = res.data.filter((item) => item.type !== "folder");
  const itemsWithDownloadUrl = await Promise.all(items.map(async item => {
    const itemDetails = await getItem(item.id); // Obtener detalles del ítem
    return { ...item, downloadUrl: itemDetails.downloadUrl }; // Combinar detalles con el ítem original
  }));

  return itemsWithDownloadUrl;
};
