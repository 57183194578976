import { AltButton } from "./AltButton";
import { Row } from "./Flex";
import { Icon, icons } from "./Icon";
import { Subtitle } from "./Text";

export const Breadcrum = ({ items, onItemClick }) => {
  return (
    <Row gap='2px' rowGap='16px' alignItems='center' flexWrap='wrap'>
      {!items && <Subtitle>Cargando...</Subtitle>}
      {items &&
        items.map(({ id, name }, i) => {
          const isLast = i === items.length - 1;

          return (
            <Row key={id} gap='2px' alignItems='center'>
              <AltButton itemCount={items.length} onClick={() => onItemClick({ id, name })} text={name} />
              {!isLast && <Icon as={icons.right} style={{ opacity: 0.5 }} />}
            </Row>
          );
        })}
    </Row>
  );
};
