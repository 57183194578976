import styled from "styled-components";



const Image = styled.img`
position: flex;
top: 0;
left: 0;
width: 100%;
max-width: ${p => p.theme.maxWidth};
max-height: 1080px;
object-fit: cover;
border-radius: ${p => p.theme.radius.large};
margin-top: 1rem;
`;

export default Image;


