import BackgroundImage from "../../app/images/home-background.jpg";
import { SuperTitle } from "../../components/SuperTitle";
import { Background } from "../../components/Background";
import { Card } from "../../components/Card";
import { Paragraph, Title } from "../../components/Text";
import { Link } from "../../components/Link";
import ceh from "../../app/images/ceh.JPG";
import cch from "../../app/images/cch.JPEG";
import pame from "../../app/images/pame.JPEG";
import cafe from "../../app/images/cafe.JPEG";
import Image from "../../components/Image";
export const Espacios = () => {
  return (
    <>
      <SuperTitle>Espacios de lxs Estudiantes</SuperTitle>
      <Background
        src={BackgroundImage}
        alt="Carreras - Humanidades x El Proyecto"
      />
      <Card>
        <Title>Centro de Estudiantes de Humanidades (CEH)</Title>
        <Paragraph>
          El CEH es el gremio de lxs estudiantes de Humanidades. El espacio
          físico se encuentra apenas ingresas a la Facultad de Humanidades.
          <br /> <br />  Es un lugar de encuentro, debate, promoción de derechos y acceso a la
          información. <br /><br /> 
          Horario: Lunes a Viernes de 08:00 a 20:00hs. Sábados de 9:00 a
          12:00hs.
          
        </Paragraph>
        <Image src={ceh} alt='CEH'></Image> <br />

   
        <Title>Centro de Copiados Humanidades (CCH)</Title>
        <Paragraph>
        El Centro de Copiados es el espacio en donde lxs estudiantes encontramos nuestro material de lectura para las cursadas. Además de ser el lugar en donde podes imprimir los textos que necesitas, se trata de una herramienta fundamental de autogestión de todxs lxs estudiantes y es administrado por la conducción del Centro de Estudiantes.
        <br /><br />El CCH constituye un espacio fundamental de acceso al apunte, aportando así a la permanencia en los trayectos académicos. 
        <br /><br />A través de la autogestión, se garantizan las becas de apuntes, las becas de trabajo y el sostenimiento de un precio accesible de la copia, generando un circuito solidario de redistribución de los recursos de todxs lxs estudiantes. 
        <br /><br />Horario: Lunes a Viernes de 08:00 a 20:00hs y sábados de 09:00hs a 12:00hs.

        </Paragraph>
        <Image src={cch} alt='CCH'></Image> <br />
        <Title>Punto de Acceso al Material de Estudio (PAME)</Title>
        <Paragraph>
          Es un espacio de acceso al material de estudio en donde lxs
          estudiantes contamos con computadoras con internet, barras de estudio,
          apuntes y bibliografía de las distintas carreras para intercambiar. En
          este espacio recibimos textos de todas las materias para que entren en
          una red solidaria, aquellos textos que dejamos los pueden retirar
          otrxs compañerxs y a su vez, dejar los que ya no utiliza. Podés
          consultar el material que tenemos en nuestro stock haciendo           <Link
            href="https://docs.google.com/spreadsheets/d/1eaz3Z3ShZFzAAOipYKHnP5mxoi6-gWXTGcHbooQYLgo/edit?usp=drivesdk
            "
            accent
            target="_blank"
          >
            click acá
          </Link>, lo actualizamos
          todos los días.{" "}
<br /><br />
          ¡Se encuentra en el 4 nivel siguiendo el pasillo a la derecha! <br /><br />
          Horario: Lunes a Viernes de 10:00 a 18:00hs.
        </Paragraph>
        <Image src={pame} alt='PAME'></Image> <br />

        
        
        <Title>Café de lxs Estudiantes</Title>
        <Paragraph>
          El café de lxs estudiantes es un espacio que le pertenece a todxs lxs
          estudiantes de Humanidades. Está gestionado por compañerxs de
          Humanidades por el Proyecto buscando que todxs podamos desayunar,
          merendar o comer algo rico mientras habitamos la facultad a precios
          accesibles. El café está ubicado en el primer nivel de nuestro
          edificio y abre todos los días de la semana, ¡Pasa a tomar un rico
          café con facturas! <br /><br />
          Horarios: Lunes a Viernes de 08:00 a 20:00 hs
        </Paragraph>
        <Image src={cafe} alt='cafe'></Image> 

      </Card>
    </>
  );
};
