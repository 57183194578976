import styled, { css } from "styled-components";
import { AiOutlineInstagram, AiOutlineQuestionCircle } from "react-icons/ai";
import { HiChevronRight, HiMenu, HiOutlineDownload } from "react-icons/hi";
import { RiTwitterLine, RiFacebookCircleLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";
import { FiMail, FiPhone, FiFolder, FiFile, FiX } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";

export const icons = {
  facebook: RiFacebookCircleLine,
  instagram: AiOutlineInstagram,
  twitter: RiTwitterLine,
  faq: AiOutlineQuestionCircle,
  right: HiChevronRight,
  menu: HiMenu,
  search: BiSearch,
  mail: FiMail,
  phone: FiPhone,
  whatsapp: ImWhatsapp,
  download: HiOutlineDownload,
  folder: FiFolder,
  file: FiFile,
  x: FiX,
};

export const Icon = styled.svg`
  color: inherit;
  overflow: visible;
  ${p => getSize(p)}
  ${p => p.strokeWidth ?? `strokeWidth: ${p.strokeWidth};`}
  ${p =>
    p.clickable &&
    css`
      cursor: pointer;
    `}
`;

const getSize = ({ size = 24 }) => css`
  height: ${size}px;
  width: ${size}px;
`;

export const SmallIcon = props => <Icon {...props} size={18} />;
