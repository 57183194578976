import styled from "styled-components";

export const Link = styled.a`
  --main-color:  ${p => (p.accent ? p.theme.color.accent : p.theme.color.white)};
  
  color: var(--main-color);
  transition: 0.2s;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid var(--main-color);
  }
`;
