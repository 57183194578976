import { FaqCard } from "./FaqCard";
import { Background } from "../../components/Background";
import BackgroundImage from "../../app/images/home-background.jpg";

import { SuperTitle } from "../../components/SuperTitle";
import { useTitle } from "../../app/hooks/useTitle";

export const PreguntasFrecuentes = () => {
  useTitle("HxEP - Preguntas Frecuentes");

  return (
    <>
      <Background src={BackgroundImage} alt='Humanidades x El Proyecto' />
      <SuperTitle>Preguntas Frecuentes</SuperTitle>
      <FaqCard />
    </>
  );
};
