import styled from "styled-components";
import { Button } from "../../components/Button";
import { Icon, icons } from "../../components/Icon";

export const SocialMedia4 = () => {
    return (
      <Wrapper>
        <a href='https://www.instagram.com/hxepfilo/' target='_blank' rel='noreferrer'>
          <Button variant='secondary' rounded>
            @hxepfilo
            <Icon as={icons.instagram} size='20px' />
          </Button>
        </a>
      </Wrapper>
    );
  };

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 16px;
position: absolute;
right: 48px;

${p => p.theme.mq.small} {
  top: 96px;
  right: 16px;
}
`;