import styled from "styled-components";
import { Column } from "./Flex";
import { Span } from "./Text";

export const Footer = () => {
  const color = "#707070";

  return (
    <Column
      width='100%'
      gap='0'
      alignItems='stretch'
      style={{ borderTop: "1px solid #1553A640", background: "#FFFFFF", position: "absolute", bottom: 0, left: 0, right: 0 }}
    >
      <Content>
        <Span color={color} size='14px'>
          Facultad de Humanidades - UNMDP <br />
          Funes 3350 - Mar del Plata - Buenos Aires - Argentina
        </Span>
        <Span align='end' color={color} size='14px'>
          ceh.unmdp@gmail.com
        </Span>
      </Content>
    </Column>
  );
};

const Content = styled.div`
  margin: auto;
  width: calc(100% - 48px * 2);
  max-width: ${p => p.theme.maxWidth}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  gap: 16px;

  ${p => p.theme.mq.medium} {
    padding: 32px 48px;
    flex-direction: column;
    align-items: start;

    span {
      text-align: start;
    }
  }

  ${p => p.theme.mq.small} {
    padding: 16px 16px;
  }
`;
