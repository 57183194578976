import BackgroundImage from "../../app/images/carreras-background.jpg";
import { Background } from "../../components/Background";
import { useTitle } from "../../app/hooks/useTitle";
import { Card } from "../../components/Card";
import { Column, Row } from "../../components/Flex";
import { Tab } from "../../components/Tab";
import { Link } from "../../components/Link";
import { useState } from "react";
import { Divider } from "../../components/Divider";
import { Paragraph, Subtitle, Title } from "../../components/Text";
import { SuperTitle } from "../../components/SuperTitle";
import { Icon, icons } from "../../components/Icon";
import { CAREERS } from "../../app/api";
import { SocialMedia0 } from "./SocialMedia0";
import { SocialMedia1 } from "./SocialMedia1";
import { SocialMedia2 } from "./SocialMedia2";
import { SocialMedia3 } from "./SocialMedia3";
import { SocialMedia4 } from "./SocialMedia4";
import { SocialMedia5 } from "./SocialMedia5";
import { SocialMedia6 } from "./SocialMedia6";
import { SocialMedia7 } from "./SocialMedia7";
import { SocialMedia9 } from "./SocialMedia9";
import { SocialMedia10 } from "./SocialMedia10";

export const Carreras = () => {
  useTitle("HxEP - Carreras");
  const [currentCareer, setCurrentCareer] = useState(CAREERS[0]);

  const careers = {
    [CAREERS[0].id]: (
      <>
        <Title>Antropología</Title>
        <Paragraph>
          Después de 40 años, en su histórica reapertura tras su cierre en la
          última dictadura cívico-militar, la carrera de Antropología reabrió
          este año y cuenta tanto con Licenciatura como con Tecnicatura.
        </Paragraph>
        <Paragraph>
          La Antropología integra una mirada holística de la humanidad como
          especie social, cultural y biológica. Aporta una larga y profunda
          temporalidad desde los orígenes de la humanidad y fundamenta su
          epistemología en la desnaturalización y el extrañamiento que permiten
          abordar la multiplicidad y diversidad de las formaciones sociales y
          culturales.
        </Paragraph>
        <Paragraph>
          Lx tecnicx en Antropología poseerá conocimientos que le permitirán
          acompañar en calidad de auxiliar proyectos de investigación, de
          intervención y/o gestión, e integrarse en equipos interdisciplinarios,
          así como también asesorar , acompañando equipos formados por
          profesionales de título de grado. La licenciatura en Antropología
          incluye tres orientaciones en las que tradicionalmente se desarrolla
          la disciplina: la Sociocultural, la Arqueología y la Bioantropología.
          Lx Licenciadx dispondrá de conocimientos que le permitirán dirigir,
          diseñar, ejecutar y evaluar proyectos de investigación antropológica
          en las áreas correspondientes a cada orientación. También puede
          acompañar en el diseño, la ejecución y evaluación de proyectos
          vinculados a las restantes orientaciones y dirigir, diseñar, ejecutar
          y evaluar proyectos interdisciplinarios desde la articulación de su
          especificidad teórico metodológica.
        </Paragraph>
        <Paragraph>
          Desde Hxep Antropología creemos en la organización como herramienta de
          transformación, por eso invitamos a todxs nuestrxs compañerxs a
          participar de la construcción de nuestra carrera a partir de su
          histórica reapertura. Estamos convencidxs de la importancia de generar
          ámbitos de participación para construir una carrera con perspectiva
          estudiantil. A 49 años de su cierre, todxs lxs que deseamos estudiar
          antropología nos encontramos en los pasillos de la Universidad
          Pública.
        </Paragraph>

        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EcvMpzT5UsdHvapT9PctLM4Bzm4S5wTogdvb90Os2g_gJA?e=78GdFr"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Licenciatura
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ES1WkllgDHdAqxU-GGrVc2MBZ5DcMtaLpX72EQ_4DHIaew?e=UWDrRb"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Orientaciones
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/Eo_wyulTcWtKiq9NAwo1qAUBHK1LsJnF2oBWFR5328gEYw?e=LrsYXv"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[1].id]: (
      <>
        <Title>Ciencia Política</Title>
        <Paragraph>
          La Licenciatura en Ciencia Política de la Facultad de Humanidades en
          la UNMDP se creó originalmente en 1972, en 1977 por resolución de la
          Delegación Militar se cerró junto con las carreras de Sociología,
          Antropología y Psicología en el marco de una avanzada contra las
          disciplinas consideradas problemáticas por la dictadura
          cívico-militar. Tras casi tres décadas de democracia, la reapertura de
          la Licenciatura en Ciencia Política respondió a demandas que llevaron
          adelante graduadxs, docentes y potenciales estudiantes retomando el
          dictado de la carrera en el año 2016.
        </Paragraph>
        <Paragraph>
          La Ciencia Política se aboca al estudio y el análisis de las
          relaciones de poder, implícitas o explícitas, entre la autoridad y los
          individuos, los grupos, y las organizaciones; y las estructuras,
          procedimientos y procesos a través de los cuales se llega a las
          decisiones políticas y se desarrollan las interacciones entre los
          diferentes sistemas políticos.
        </Paragraph>
        <Paragraph>
          Lxs graduadxs de Ciencia Política son capaces de interpretar y
          explicar las estructuras, procedimientos y procesos políticos, en
          función del análisis de las relaciones de poder entre la autoridad y
          los individuos, los grupos y las organizaciones. Además, poseen la
          formación necesaria para realizar el diseño e implementación de
          instituciones y organizaciones a través de las cuales se llega a
          decisiones políticas y evaluar su dinámica y eficacia.
        </Paragraph>
        <Paragraph>
          Desde La Simón Bolivar, lxs representantes estudiantiles de Ciencia
          Política, apostamos a que nuestra disciplina pueda aportar al
          desarrollo nacional de nuestro país, transformando la realidad de
          todxs lxs argentinxs a partir de nuestra formación. Así, lxs
          politólogxs tenemos la tarea de convertirnos en profesionales que
          brinden sus conocimientos al servicio del pueblo argentino, para
          alcanzar una sociedad más justa e igualitaria.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Dai: 2236323240
              <br />
              <br />
              Lucho: 2235331771
              <br />
              <br />
              Cami: 2236021623
            </Row>
            <Paragraph>Armado por carrera: La Simón Bolívar</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/lasimonbolivarunmdp/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://instagram.com/lasimonbolivarunmdp/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EQoJz7G0TZNLlVHqI6tSiRcB7TO7EBYeJ-8NuDlB6H0UZA?e=DhuEAV"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Licenciatura
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/EmDTBElD4OZGh5mVn-YdVUcBHZBDQelvvkdIc-i-UKWEgQ?e=FTyAVR"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[2].id]: (
      <>
        <Title>Cs. de la Educación</Title>
        <Paragraph>
          La Licenciatura en Ciencias de la Educación es una carrera reabierta
          en 2019 después de 41 años de ausencia por el cierre establecido por
          la última dictadura cívico-militar. La carrera, habiendo iniciado su
          actividad 8 años antes, es clausurada en 1978 en el marco de la
          persecución al pensamiento crítico que se gestaba en las
          Universidades, mediante OCS N°104/78. La reapertura de la carrera está
          marcada por marchas y contramarchas que proponen la memoria activa de
          la historia que marca nuestras trayectorias. Posteriormente, en 2022,
          se abrieron las puertas del Profesorado ampliando los horizontes de
          lxs estudiantes.
        </Paragraph>
        <Paragraph>
          Las Ciencias de la Educación conforman un campo disciplinar amplio en
          el que se entraman miradas de la pedagogía, la didáctica, la
          filosofía, la sociología o la psicología en el contexto educativo. La
          formación en educación nos permite hacer preguntas del ámbito
          educativo formal e informal, de las prácticas docentes, de las
          propuestas curriculares o las políticas educativas abarcando las
          muchas dimensiones del acontecer social en relación con la educación.
        </Paragraph>
        <Paragraph>
          La Licenciatura, orientada a la investigación; y el Profesorado,
          orientado a la docencia en el nivel superior comparten múltiples áreas
          de desempeño profesional. Lxs cientistas de la educación podemos
          continuar nuestras trayectorias en Equipos de Orientación Escolar,
          espacios de asesoramiento pedagógico y normativo, el ejercicio de
          diseño de proyectos y actividades educativas, evaluar instancias
          formativas, entre otros espacios donde lo educativo acontece.
        </Paragraph>
        <Paragraph>
          Desde CCE-HxEP conformamos un espacio de participación estudiantil
          activa en el Departamento de Ciencias de la Educación. Como
          representantes estudiantiles estamos convencidos del rol social,
          situado, crítico y necesario de nuestra carrera para co-construir una
          educación al servicio del pueblo, entendida como un derecho
          fundamental.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Caro: 2236964282
              <br />
              <br />
              Ihan: 2236836480
              <br />
              <br />
              Naty: 2235502146
            </Row>
            <Paragraph>
              Armado por carrera: Ciencias de la Educación HxEP
            </Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/cienciasdelaeducacionhxep/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://instagram.com/cce_hxep/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EQg6U_AAUh9AqwDxcggsUToBj7wxj7MhYZU3_ty4rFxOBA?e=0lnw1h"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Licenciatura
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/Ef3k9QxbRSZKtoOzfNxaEsABVi8bJgLf3sZao13PjNaYKQ?e=sq83vd"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Profesorado
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/EmG9u3zcK_hOrutwhP5SYgoBDDxRRXfcJp4dQjkwMNdc-g?e=OZ9hEt"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[3].id]: (
      <>
        <Title>Cs. de la Información</Title>
        <Paragraph>
          Bibliotecología tiene 4 carreras ofertadas desde el Departamento de
          Ciencia de la Informacion, Bibliotecarix Escolar, Bibliotecarix
          Documentalista con orientaciones en Cientificx- Técnicx,
          Socio-comunitaria, Archivista y en Museología (Carreras de pre-grado).
          Profesorado y Licenciatura en Bibliotecología y Documentación
          (Carreras de grado).
        </Paragraph>
        <Paragraph>
          La carrera ofrece formar profesionales expertxs en gestión de
          información, propiciar la cultura de la investigación y de la creación
          de nuevo conocimiento en el campo de la información a través de la
          educación. Además estimular el ejercicio del pensamiento crítico en
          los espacios formativos, en busca de la intervención efectiva en la
          realidad del entorno significativo.
        </Paragraph>
        <Paragraph>
          Desde HxEP Biblio, reafirmamos nuestro compromiso en la construcción
          de una carrera donde se ejerzan las cuatro orientaciones para poder
          seguir formando el rol del profesional de la información. Así también
          para que la voz de lxs estudiantes sea visible a la hora de luchar por
          sus derechos.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Juli: 2236351293
            </Row>
            <Paragraph>Armado por carrera: HxEP Biblio</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/HXEPBIBLIO/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://www.instagram.com/hxepbiblio/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/Ef1JAj1wNjZFiXXzic8rmngBMbjtx-rwb38Xk8wfdXC1Rw?e=byrexA"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - pregrado Bibliotecario Escolar (presencial y a distancia)
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EeEz-DPpc8dMsJohM836pVABM7kz_eU9guwpj65FidT3lQ?e=tvuihy"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - pregrado Bibliotecología (presencial)
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EVqE0CuORfVAgrcZ8dwsUI4BjNPz1WD-LtZlF8J_0FBQkw?e=ypPcew"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Lic. en Bibliotecología (a distancia)
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EY7yz5EBcjhLkGcD5xERYVwBn0pAQ3JdHFz1hrJ8oXZuKQ?e=m4bxmZ"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Prof. en Bibliotecología (presencial)
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/ErWXRivJcLRMlM6SgewwTmQBkKSxa0KI_jiFzAh0CIVChg?e=uQFmC9"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[4].id]: (
      <>
        <Title>Filosofía</Title>
        <Paragraph>
          La carrera de Filosofía fue reabierta en la UNMdP en el año 1994,
          atendiendo así a la demanda de la democracia de formar profesionales
          en esta disciplina. La Filosofía, como cuerpo de problemas, se
          posiciona abierta a distintos abordajes. Esta particularidad
          disciplinar es tanto su mayor ventaja como la fuente de la dificultad
          a la hora de presentarla a quienes se acercan a ella por primera vez.
        </Paragraph>
        <Paragraph>
          A pesar de las dificultades, podemos intentar definir a la Filosofía
          como la disciplina que se ocupa del estudio de problemas en torno a
          cómo concebimos el conocimiento, el ser, el lenguaje, la moral, el
          arte, la verdad, la razón, la ciencia, el significado, entre
          muchísimos otros.
        </Paragraph>
        <Paragraph>
          Al día de hoy, Filosofía en la Universidad de Mar del Plata se
          organiza en distintas áreas que buscan englobar algunos de los
          cuestionamientos que han sabido plantear lxs filosofxs: área
          histórica, área socioantropológica, área de teoría del conocimiento,
          área metafísica, área del lenguaje. Las asignaturas pertenecientes a
          nuestra carrera se distribuyen en las áreas presentadas.
        </Paragraph>
        <Paragraph>
          La Universidad de Mar del Plata otorga a quienes elegimos esta
          disciplina los títulos de licenciadx y/o profesorx en Filosofía. Los
          alcances de los mismos son, a grandes rasgos, la docencia (en nivel
          secundario y superior tanto universitario como no universitario) y la
          investigación. Sin embargo, puede que las salidas laborales se hayan
          visto incrementadas en este último tiempo debido a los avances
          tecnológicos. Queda aún bastante por explorar. En este sentido, desde
          HxEP Filo creemos fundamental generar instancias para repensar tanto
          nuestra disciplina como nuestras salidas laborales. Confiamos en que
          sólo así podemos construir una Filosofía con perspectiva feminista y
          latinoamericana que importe para la construcción del país que nos
          merecemos.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Juani: 2291416350
              <br />
              <br />
               Pri: 2236925531
            </Row>
            <Paragraph>Armado por carrera: HxEP Filo</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/HXEP.FILO/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://www.instagram.com/hxepfilo/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EWXE73oTfX5EijyePFwP234BXgzfgBFunF67ok4Ku5wOCg?e=snnudI"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/Eq2MrqyiZflIpEWnuWlEBKIBsvt_plZpE--keZi7bPJOEA?e=a9gwLi"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[5].id]: (
      <>
        <Title>Geografía</Title>
        <Paragraph>
          La carrera de Geografía en nuestra facultad cuenta tanto con un
          profesorado como con una licenciatura. La Geografía abre un espacio de
          descubrimiento y explicación a la dimensión territorial de los
          procesos sociales, valorizando el marco físico-natural en el que están
          inmersos.
        </Paragraph>
        <Paragraph>
          Como licenciadx, estará en condiciones de plantear, diseñar y
          desempeñarse en tareas de investigación científica, así como también
          en la planificación en equipos interdisciplinarios estatales y
          privados para la gestión del territorio y del ambiente. En cuanto al
          profesorado, brinda información profesional para enseñar, planificar y
          evaluar contenidos del campo disciplinar en los niveles secundario,
          terciario y universitario en instituciones tanto públicas como
          privadas.
        </Paragraph>
        <Paragraph>
          Desde Hxep GEO, apostamos a construir una carrera plural, crítica, y
          participativa, en la que las demandas estudiantiles estén contenidas y
          en la que el conocimiento construido esté al servicio del desarrollo y
          la soberanía nacional.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Ro: 2235457203
            </Row>
            <Paragraph>Armado por carrera: HxEP Geo</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/people/Hxep-Geo/100064412065063/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://www.instagram.com/hxep_geo/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EabUJhrx8iBGqJzHMxUPE_sBbbHeekUAxbqwsJY1k7L2nA?e=2apu78"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Licenciatura
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ERs3RrvBY75Lg-IgSNTYx0UBsNHFxv73t0vh3FeAGB9qVw?e=Lch8lf"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Profesorado
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/ErJdzyAIKBdPi4f0jTAwEHEBI1YFH0uyISS8zWzNPB5Jxw?e=t7OPcw"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[6].id]: (
      <>
        <Title>Historia</Title>
        <Paragraph>
          Los planes de estudio de Historia cuentn con Licenciatura y
          Profesorado. Contienen una formación disciplinar que busca que lxs
          estudiantes entendamos la realidad social y las problemáticas del
          presente a partir de la información recuperada del pasado,
          identificando cambios y continuidades en los procesos históricos a
          nivel local, regional, nacional e internacional.
        </Paragraph>
        <Paragraph>
          La licenciatura nos permite obtener herramientas para analizar la
          realidad social utilizando el método científico y la imaginación
          investigativa.
        </Paragraph>
        <Paragraph>
          En el profesorado adquirimos herramientas pedagógicas y didácticas
          para desempeñarnos como docentes en escuelas, institutos y
          universidades.
        </Paragraph>
        <Paragraph>
          Desde Hermenéutica en Historia buscamos fomentar la participación de
          lxs estudiantes de diferentes años para pensar una carrera con una
          mirada crítica, latinoamericana, popular y feminista, acorde a los
          nuevos tiempos que corren. Acompañar en la trayectoria de nuestrxs
          compañerxs y defender nuestros derechos en el Consejo Asesor
          Departamental es lo que nos ha caracterizado en estos casi diez años
          de construcción, lxs compañerxs de historia seguimos apostando por
          este camino.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Azul: 2235036565
              <br />
              <br />
              Facu: 3814069682
            </Row>
            <Paragraph>Armado por carrera: Hermenéutica en Historia</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/hermeneuticaenhistoria/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://instagram.com/hermeneutica.unmdp"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/Ecp3hwFX6VFChOYaXGQuwU4BdtWPimqR9fPrv1ZqVZxm7A?e=thQ3zt"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Licenciatura
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EZQMqoQn2_BGpn5lC-3aojsBUiqSPTwhPvREDEGl1ibSCg?e=pZ90YT"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Profesorado
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/EvpOn0Wf8_pHgBlQz3HACf0BxBpvuvzostPf9AoP-f3ZMg?e=3NIj4m"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[7].id]: (
      <>
        <Title>Italiano</Title>
        <Paragraph>
          El plan de estudio del Profesorado en Lengua Italiana busca
          proporcionar una formación integral para aquellos interesados en
          enseñar el idioma. La formación docente en lenguas extranjeras debe
          abordar los desafíos actuales y promover el conocimiento del idioma,
          la reflexión sobre las implicancias pedagógicas y la integración
          intercultural.
        </Paragraph>
        <Paragraph>
          Al tratarse de un profesorado de la Facultad de Humanidades, se espera
          que el egresado cuente con una formación crítica, reflexiva y
          humanista vinculada al análisis de la realidad social, cultural e
          histórica de los individuos. El título de Profesor/a Universitario/a
          en Lengua Italiana acredita conocimientos actualizados para
          desarrollar su labor profesional; competencias para ejercer la
          docencia en los niveles secundario y superior del sistema educativo
          con una formación amplia y diversificada para ello; capacidad para
          participar en equipos multi e interdisciplinarios, con compromiso
          social y con capacidad científica.
        </Paragraph>
        <Paragraph>
          Desde HxepItaliano, apostamos a que el Profesorado en Lengua Italiana
          sea una carrera que cuente con derechos estudiantiles amplios y
          también a poder establecer lazos entre lxs actores que integran el
          profesorado para fomentar el diálogo en una construcción conjunta de
          la carrera. Además, incentivamos la incorporación de actividades extra
          académicas que aporten a una formación integral del estudiante tanto
          en materia pedagógica como cultural.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Lu: 2234481038
              <br />
              <br />
              Cata: 2236228398
            </Row>
            <Row>
              <Link
                href="https://www.instagram.com/hxep.italiano/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/Ed2jkCK8KFRCgpbgu0Gc2IAB1bB03gGHhfOXg2ou-tv9dw?e=KwPjmk"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/En5JSCQWbnxFoot3dSojzdUBdCVWrKrOvi0-Vm6gy7QLnQ?e=Vqxjog"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[8].id]: (
      <>
        <Title>Lenguas Modernas</Title>
        <Paragraph>
          El Departamento de Lenguas Modernas ofrece dos carreras: Profesorado
          de Inglés y Licenciatura en Lengua Inglesa.
        </Paragraph>
        <Paragraph>
          El título de Licenciado/a en Lengua Inglesa acredita conocimientos
          teóricos, prácticos y metodológicos con los cuales quien egrese podrá
          intervenir en los diferentes escenarios y espacios públicos y privados
          a nivel local, regional, nacional e internacional. Contará con las
          bases conceptuales y prácticas necesarias para entender la complejidad
          del quehacer científico en general en lo que concierne a la lengua
          inglesa como lenguaje de comunicación internacional en particular.
        </Paragraph>
        <Paragraph>
          {" "}
          El Profesorado de Inglés, por su parte, brinda también un sólido
          manejo teórico-práctico del idioma, los fundamentos culturales que son
          el sustento de la lengua inglesa y su comparación con la cultura
          nacional, así como un trayecto de formación docente. El título
          habilita para el ejercicio de la docencia en nivel inicial, primario,
          secundario, terciario y universitario, tanto en establecimientos de
          gestión pública como privada. Además, quienes egresen podrán
          desarrollar funciones que requieran el uso del inglés en tareas tales
          como relaciones públicas y exteriores, comercio exterior, periodismo,
          publicidad, turismo, y organismos internacionales.
        </Paragraph>
        <Paragraph>
          Esperamos avanzar hacia un profesorado en el que Ixs estudiantes
          seamos sujetxs activxs en la construcción de conocimientos, podamos
          enmarcar la práctica docente en el contexto en el que estamos inmersxs
          y nos formemos para derribar mitos pre-establecidos sobre la enseñanza
          y el aprendizaje de un lenguaje extranjero, para así hacerlo más
          accesible a todxs.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Cande: 2234234254
              <br />
              <br />
              Iara: 2235863465
            </Row>
            <Paragraph>Agrupación de la Carrera: ASAP</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/asaplenguasmodernas/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://instagram.com/asaplenguasmodernas/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EachYI7-zKZFpJ3qdgINZVgB0WheuUB5pX6jR4BodtNgwA?e=kJUDmH"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Lic. en Lengua Inglesa
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EfAij43W3fRKs4pYqFv2GzMBbbbisbVVNSODFFxkIA6qPQ?e=aNwiZN"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Prof. en Inglés (2023)
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EQgdIwP1Wm1NgBKZIiwx2zQB_62fRytfLxcX7SRKqlOeYw?e=AXhJKg"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Prof. en Inglés (1999)
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/EvPI5cwkidBOlbwCiDQx1EYB-fc1BZsN2o4G2kHgzmI0bg?e=78FyHJ"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[9].id]: (
      <>
        <Title>Letras</Title>
        <Paragraph>
          La carrera de Letras se encuentra en Mar del Plata desde la década del
          60, en la Facultad de Humanidades desde 1975. Actualmente lxs
          estudiantes de Letras se forman en distintas y variadas áreas y
          disciplinas: las literaturas, sus teorías, las ciencias del lenguaje.
          Cada una con su especificidad pero todas apuntando a formar
          profesionales con pensamiento crítico.
        </Paragraph>
        <Paragraph>
          Desde hace unos años las posibilidades de ejercer como licenciadx o
          docente en Letras se amplían constantemente: pueden ejercer en el
          nivel medio o superior como profesorxs, pueden dedicarse a la
          investigación sobre la literatura, la lengua y todos los aspectos que
          la conforman; pero también lx egresadx de Letras se dedica al trabajo
          editorial, a dar talleres, a enseñar nuestra lengua a extranjerxs, a
          trabajar como redactorxs, cronistas o críticos.
        </Paragraph>
        <Paragraph>
          Desde hxepletras trabajamos constantemente desde el año 2020 para
          lograr una carrera con más derechos estudiantiles. Como representantes
          estudiantiles seguimos promoviendo discusiones tales como la
          promocionalidad, oferta horaria, revisión del plan de estudios e
          inserción a la docencia. Además creemos en una perspectiva de la
          carrera popular, feminista, crítica, que apueste a nuestros futuros
          como profesionales.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Luna: 2236019085
              <br />
              <br />
              Cristina: 2235050458
            </Row>
            <Paragraph>Armado por carrera: HxEP Letras</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/hxepletras/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://www.instagram.com/hxepletras/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EebS_JyiG69JlNmsZ87zlPwBbJp33AIbo5GlsH1mTjOVCg?e=fMBpUv"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Licenciatura
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ESiwZCfaGMtKuqr9s17hmkoBoh6HX2PxHQRsZpA08a6REg?e=MweqTg"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Profesorado
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/EtCjDaWoNr5IkASsQmPQCqABkxbZMhTuG8JiC1ZfL2vskQ?e=QZVZIc"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
    [CAREERS[10].id]: (
      <>
        <Title>Sociología</Title>
        <Paragraph>
          La carrera de Sociología en nuestra facultad cuenta tanto con
          licenciatura como con profesorado. Luego de ser cerrada por la última
          dictadura cívico-militar, desde su reapertura, en el año 2007, fruto
          de un extenso trabajo de reparación histórica, se inició un camino de
          reconstrucción y consolidación, abogando como ejes la construcción de
          conocimiento crítico y la excelencia profesional.
        </Paragraph>
        <Paragraph>
          La sociología brinda conocimientos teóricos y metodológicos para la
          investigación de distintas problemáticas sociales y su intervención,
          desde distintas corrientes de pensamiento y, tal como afirma Bourdieu,
          produciendo incomodidad.
        </Paragraph>
        <Paragraph>
          Como licenciadx, estará en condiciones de desempeñarse en tareas de
          investigación y extensión, asesoramiento científico y técnico, y
          dirección y ejecución de proyectos aplicados. El profesorado, brinda
          herramientas pedagógicas y didácticas de enseñanza de las ciencias
          sociales en general, y la sociología en particular, además de poder
          participar en la elaboración de diseños curriculares.
        </Paragraph>
        <Paragraph>
          Desde Deconstruyendo Sociología acompañamos las trayectorias y
          defendemos los derechos estudiantiles abogando por una carrera popular
          y con perspectiva crítica, y entendiendo a la construcción de
          conocimiento como un compromiso con nuestra realidad para su
          transformación.
        </Paragraph>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Fer: 2235450697
              <br />
              <br />
              Barbi: 2235903732
            </Row>
            <Paragraph>Armado por carrera: Deconstruyendo Sociología</Paragraph>
            <Row>
              <Link
                href="https://www.facebook.com/deconstruyendosocio/"
                accent
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                href="https://instagram.com/deconstruyendosocio/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
        <Column gap="0">
          <Divider style={{ marginBottom: "32px" }} />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/EabUJhrx8iBGqJzHMxUPE_sBbbHeekUAxbqwsJY1k7L2nA?e=qenQmp"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Licenciatura
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:b:/g/personal/juancristini_infohuma_onmicrosoft_com/ERs3RrvBY75Lg-IgSNTYx0UBsNHFxv73t0vh3FeAGB9qVw?e=TyFcYS"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Plan de Estudio - Profesorado
            </Subtitle>
          </a>
          <br />
          <a
            target="_blank"
            href="https://infohuma-my.sharepoint.com/:f:/g/personal/juancristini_infohuma_onmicrosoft_com/Ehuxeid65mhGpnGDlH5POckBYVemuEfj0zxvww3HNG8akQ?e=YlwOlx"
          >
            <Subtitle>
              <Icon as={icons.file} />
              Material de Estudio
            </Subtitle>
          </a>
          {/*<FileSystemOneDrive rootFolderId={currentCareer.id} breadcrum={false} />*/}
        </Column>
      </>
    ),
  };

  return (
    <>
      <SuperTitle>Carreras</SuperTitle>
      <Background
        src={BackgroundImage}
        alt="Carreras - Humanidades x El Proyecto"
      />
      {currentCareer.id === CAREERS[0].id && <SocialMedia0 />}
      {currentCareer.id === CAREERS[1].id && <SocialMedia1 />}
      {currentCareer.id === CAREERS[2].id && <SocialMedia2 />}
      {currentCareer.id === CAREERS[3].id && <SocialMedia3 />}
      {currentCareer.id === CAREERS[4].id && <SocialMedia4 />}
      {currentCareer.id === CAREERS[5].id && <SocialMedia5 />}
      {currentCareer.id === CAREERS[6].id && <SocialMedia6 />}
      {currentCareer.id === CAREERS[7].id && <SocialMedia7 />}
      {currentCareer.id === CAREERS[9].id && <SocialMedia9 />}
      {currentCareer.id === CAREERS[10].id && <SocialMedia10 />}
      <Card>
        <Row flexWrap="wrap" justifyContent="center">
          {CAREERS.map((career) => (
            <Tab
              selected={career.id === currentCareer.id}
              key={career.id}
              onClick={() => setCurrentCareer(career)}
            >
              {career.name}
            </Tab>
          ))}
        </Row>
        <Divider />
        {careers[currentCareer.id]}
      </Card>
    </>
  );
};
