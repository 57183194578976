import { useCallback, useEffect, useState } from "react";
import { getItem, getItemChildren, getSearchResults } from "../../app/api";
import { useDidMount } from "../../app/hooks/usedidMount";

export const useFileSystemOneDrive = (rootFolderId, integratedSearch) => {
  const didMount = useDidMount();
  const [breadcrumItems, setBreadcrumItems] = useState();
  const [contentItems, setContentItems] = useState();
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [searchStatus, setSearchStatus] = useState({ value: "empty", query: "" });

  const serchBarIsEmpty =
    searchStatus.value === "empty" && (searchStatus.query === undefined || searchStatus.query === "");

  const clearContent = () => setContentItems(undefined);

  const changeContentByItemId = useCallback(async id => {
    clearContent();
    setIsLoadingContent(true);
    const newContentItems = await getItemChildren(id);
    setContentItems(newContentItems);
    setIsLoadingContent(false);
  }, []);

  const changeBreadcrumByItem = useCallback(
    item => {
      const breadcrumIds = breadcrumItems.map(i => i.id);
      const index = breadcrumIds.indexOf(item.id);
      const isInBreadcrum = index !== -1;
      const hasMultipleItems = breadcrumItems.length > 1;

      if (isInBreadcrum && hasMultipleItems) {
        const slicedBreadcrumItems = breadcrumItems.slice(0, index + 1);
        setBreadcrumItems(slicedBreadcrumItems);
      } else {
        setBreadcrumItems(prev => (prev ? [...prev, item] : [item]));
      }
    },
    [breadcrumItems]
  );

  const goToItem = useCallback(
    async item => {
      changeBreadcrumByItem(item);
      changeContentByItemId(item.id);
    },
    [changeBreadcrumByItem, changeContentByItemId]
  );

  const changeContentBySearch = useCallback(
    async query => {
      const newContentItems = await getSearchResults(rootFolderId, query);
      setContentItems(newContentItems);
      setIsLoadingContent(false);
    },
    [rootFolderId]
  );

  useEffect(() => {
    getItem(rootFolderId).then(item => setBreadcrumItems([item]));
    changeContentByItemId(rootFolderId);
  }, [rootFolderId, changeContentByItemId, integratedSearch]);

  useEffect(() => {
    if (didMount) return;
    const status = searchStatus.value;
    console.log(status);
    if (status === "writingQuery") {
      clearContent();
      setIsLoadingContent(true);
    } else if (status === "ready") {
      changeContentBySearch(searchStatus.query);
    } else if (status === "empty") {
      getItem(rootFolderId).then(item => setBreadcrumItems([item]));
      changeContentByItemId(rootFolderId);
    }
  }, [searchStatus]);

  return {
    state: { breadcrumItems, contentItems, isLoadingContent, serchBarIsEmpty },
    goToItem,
    setSearchStatus,
  };
};
