import { getMediaQuery } from "./util";

export const breakpoints = {
  small: 568,
  medium: 760,
  large: 1140,
  extraLarge: 1920,
};

export const theme = {
  maxWidth: 1920,
  headerHeight: 70,
  font: ["AlteHaasGrotesk", "Rubik"],
  color: {
    primary: "#1553A6",
    accent: "#11A99A",
    light: "#F2F2F2",
    dark: "#3C3C3B",
    white: "#FFFFFF",
  },
  radius: {
    small: "5px",
    medium: "10px",
    large: "20px",
  },
  mq: {
    small: getMediaQuery(breakpoints.small),
    medium: getMediaQuery(breakpoints.medium),
    large: getMediaQuery(breakpoints.large),
    extraLarge: getMediaQuery(breakpoints.extraLarge),
  },
  shadow: "0 4px 5px #0000001f",
  setBorder: (width, color) => `box-shadow: inset 0px 0px 0px ${width}px ${color};`,
};
