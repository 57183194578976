import styled, { css } from "styled-components";

export const Tab = styled.div`
  border-radius: ${p => p.theme.radius.small};
  color: ${p => p.theme.color.dark};
  background-color: ${p => p.theme.color.light};
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: .1s;

  &:hover {
    color: ${p => p.theme.color.light};
    background-color: ${p => p.theme.color.dark};
  }

  ${p => p.selected && css`
    background-color: ${p => p.theme.color.accent};
    color: ${p => p.theme.color.white};
  `};
`;