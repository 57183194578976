import styled from "styled-components";

export const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: ${p => p.theme.maxWidth};
  height: 100vh;
  max-height: 1080px;
  object-fit: cover;
  z-index: -1;
`;