import styled from "styled-components";

export const Main = styled.main`
  --padding-top: calc(${p => p.theme.headerHeight}px + 48px);

  margin: auto;
  max-width: ${p => p.theme.maxWidth}px;
  display: flex;
  padding: 48px 48px 160px 48px;
  padding-top: var(--padding-top);
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  ${p => p.theme.mq.small} {
    padding: 16px 16px 160px 16px;
    padding-top: var(--padding-top);
  }
`;
