import styled from "styled-components";
import { Card } from "../../components/Card";
import { Icon, icons } from "../../components/Icon";
import { Paragraph, Subtitle, Title } from "../../components/Text";

export const WelcomeCard = () => {
  return (
    <Card>
      <Title>Bienvenidxs</Title>
      <Paragraph>
      Somos compañerxs de todas las carreras de la Facultad de Humanidades que desde hace más de 10 años nos organizamos en Humanidades por el Proyecto para construir una facultad que garantice la mirada estudiantil en cada uno de los trayectos académicos y la ampliación de nuestros derechos de cursada.
      <br /><br />
      En este contexto nos organizamos para hacerle frente a las políticas de desmantelamiento del gobierno actual, entendiendo que tenemos un rol clave en la lucha por la defensa de nuestros derechos que son amenazados por los gobiernos neoliberales.
      <br /><br />
      En Info Humanidades lxs estudiantes vamos encontrar información referida a las carreras de la Facultad, novedades sobre las políticas del CEH y sus secretarías, trámites administrativos que solemos hacer, nuestros derechos de cursada y normativas entre otras cosas. Además, ¡Vas a poder acceder de forma libre y gratuita a la bibliografía de las materias que cursamos!
      </Paragraph>
      <a target="_blank" href="https://www.instagram.com/hxep_unmdp/">
        <Subtitle>
          <Icon as={icons.instagram} />
          Últimas publicaciones
        </Subtitle>
      </a>
      <Feed
        className='juicer-feed'
        data-feed-id='hxep_unmdp'
        data-per='3'
        data-pages='1'
        data-style='slider'
        data-overlay='false'
        id="estatutos"
      />
    </Card>
  );
};

const Feed = styled.ul`
@media screen and (min-width: 480px){
  .slick-track {
    width: 1400px !important;
    ::before, ::after {
        display: none !important;
      }
  }
}
  @media screen and (min-width: 786px){
    li {
      width: 30% !important;
      height: unset !important;
      border-radius: ${p => p.theme.radius.small};
      overflow: hidden;
      img {
        width: 100% !important;
        height: 100% !important;
      }
      div {
        display: none !important;
      }
    }
    .slick-track {
      height: unset !important;
      width: 100% !important;
      display: flex !important;
      justify-content: space-between;

      ::before, ::after {
        display: none !important;
      }
    }
  }
`;
