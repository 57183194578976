import { PDFHumanidadesCard } from "./PDFHumanidadesCard";
import { Background } from "../../components/Background";
import BackgroundImage from "../../app/images/pdf-humanidades-background.jpg";
import { SuperTitle } from "../../components/SuperTitle";
import { useTitle } from "../../app/hooks/useTitle";
import { SocialMediaPDF } from "./SocialMediaPDF";

export const PDFHumanidades = () => {
  useTitle("HxEP - PDF Humanidades");

  return (
    <>
      <SuperTitle>PDF Humanidades</SuperTitle>
      <SocialMediaPDF></SocialMediaPDF>
      <Background src={BackgroundImage} alt='PDF Humanidades - Humanidades x El Proyecto' />
      <PDFHumanidadesCard />
    </>
  );
};