
import { Card } from "../../components/Card";
import { Icon, icons } from "../../components/Icon";
import { Title } from "../../components/Text";
import { Paragraph } from "../../components/Text";
import { Subtitle } from "../../components/Text";
import { Divider } from "../../components/Divider";

export const CursadasSolidadariasCard = () => {
  return (
    <Card>
      <Title>
        {/*<Icon as={icons.file} />*/}
        Cursadas Solidarias
      </Title>
      <Paragraph>
      Cursadas Solidarias es un proyecto colaborativo que tiene el objetivo de generar redes y espacios de contención entre compañerxs de cursada.
      <br /><br />
      Como conducción del CEH, desde Humanidades por el Proyecto creemos que es fundamental construir herramientas para promover que nuestros trayectos formativos se desarrollen en un marco colectivo, donde la solidaridad y el acompañamiento entre pares sean los pilares que sostengan nuestro paso por la Universidad Pública.
      <br /><br />
      El proyecto de “Cursadas solidarias” cuenta con herramientas como grupos de WhatsApp por materia para difundir información de nuestras cursadas, “Idas y Vuelta” (encuentros de repaso con estudiantes avanzadxs y adscriptos antes de los parciales) y grupos de estudio para repasar juntxs.
      <br /><br />
      A su vez, podes encontrar el stock del Punto de Acceso al Material de Estudio (PAME) -lo encontrás en el apartado de PAME de la página-, el espacio de lxs estudiantxs donde concentramos el material de estudio para su redistribución, para que todxs podamos hacer uso de esta herramienta. Allí, también accedemos a computadoras con conexión a Internet que pueden ser utilizadas para realizar trabajos prácticos, leer textos digitales o acceder al Aula Virtual.
      <br /><br />
      Acá abajo podés encontrar todos los idas y vuelta de este cuatrimestre: 
      <br /><br />
      <Divider style={{ marginBottom: "32px" }} />
      <a target="_blank" href="https://docs.google.com/spreadsheets/d/1VTH14UGPB3UhmJcL3RRoyTnzJgO44FFw6UJq6jEcGVo/edit?usp=sharing">
          <Subtitle>
            <Icon as={icons.file} />
            Cronograma Idas y Vueltas
          </Subtitle>
      </a>
      </Paragraph>
    </Card>
  );
};
