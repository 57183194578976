import { Row } from "../Flex";
import { Loader } from "../Loader";
import { Subtitle } from "../Text";
import { AltButton } from "../AltButton";
import { Icon, icons } from "../Icon";
import styled, { useTheme } from "styled-components";

export const FileSystemContent = ({ isLoading, items, onItemClick }) => {
  const theme = useTheme();
  const isEmpty = !isLoading && (items === undefined || items.length === 0);
  const allItemsAreImages = items?.filter(item => item.type !== "image").length === 0;

  return (
    <Row minHeight='40px' justifyContent='center' alignItems='center'>
      {isLoading && <Loader />}
      {isEmpty && <Subtitle>Sin contenido</Subtitle>}
      {!(isLoading || isEmpty) && (
        <Grid singleColumn={allItemsAreImages}>
          {items.map(item => {
            const linkProps = { as: "a", href: item.downloadUrl, target: "_blank", rel: "noreferrer" };
            const noLinkProps = { onClick: () => onItemClick(item) };
            if (allItemsAreImages && item.type === "image") {
              return <ImageItem src={item.downloadUrl} alt={item.name} />;
            }
            return (
              <AltButton
                key={item.id}
                {...(item.downloadUrl ? linkProps : noLinkProps)}
                textMaxWidth='160px'
                text={item.name}
              >
                <Icon
                  as={icons[item.downloadUrl ? "download" : "folder"]}
                  size='16px'
                  strokeWidth='3px'
                  color={theme.color.accent}
                />
              </AltButton>
            );
          })}
        </Grid>
      )}
    </Row>
  );
};

const Grid = styled.div`
  align-self: start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;

  ${p => p.theme.mq.medium} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${p => p.theme.mq.small} {
    grid-template-columns: repeat(1, 1fr);
  }

  ${p => p.singleColumn && "grid-template-columns: repeat(1, 1fr);"}
`;

const ImageItem = styled.img`
  border-radius: ${p => p.theme.radius.medium};
  width: 100%;
`;
