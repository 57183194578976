import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./app/theme";
import { Footer } from "./components/Footer";
import { GlobalStyle } from "./components/GlobalStyle";
import { Header } from "./components/Header/index.jsx";
import { Main } from "./components/Main";
import { Carreras } from "./pages/Carreras";
import { Secretarias } from "./pages/Secretarias";
import { Derechos } from "./pages/Derechos"
import { PreguntasFrecuentes } from "./pages/PreguntasFrecuentes";
import { Home } from "./pages/Home";
import { PDFHumanidades } from "./pages/PDFHumanidades";
import { Error404 } from "./pages/404";
import { Espacios } from "./pages/Espacios";
import { Normativas } from "./pages/Normativas";

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <Main>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/carreras' element={<Carreras />} />
            <Route path='/secretarias' element={<Secretarias />} />
            <Route path='/derechos' element={<Derechos />} />
            <Route path='/espacios' element={<Espacios />} />
            <Route path='/normativas' element={<Normativas />} />
            <Route path='/preguntas-frecuentes' element={<PreguntasFrecuentes />} />
            <Route path='/pdf-humanidades' element={<PDFHumanidades />} />
            <Route path='*' element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </Main>
      <Footer />
    </ThemeProvider>
  );
};
