import { Column, Row } from "./Flex";
import { Paragraph, Span } from "./Text";

import Triangle from "../app/icons/triangle.svg";
import styled from "styled-components";
import { useState } from "react";

export const Expandable = ({ title, children, isParagraph = true }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <Wrapper>
      <Row alignItems='center' onClick={toggleOpen} style={{ cursor: "pointer", padding: "16px 0" }}>
        <TriangleIcon src={Triangle} alt='triangle' rotated={open} />
        <Span weight='600' size='18px' nonSelectable lineHeight="1.3">
          {title}
        </Span>
      </Row>
      {open && isParagraph && <Paragraph style={{paddingBottom: "16px"}}>{children}</Paragraph>}
      {open && !isParagraph && <div style={{paddingBottom: "16px"}}>{children}</div>}
    </Wrapper>
  );
};

const Wrapper = styled(Column)`
  background-color: ${p => p.theme.color.light};
  border-radius: ${p => p.theme.radius.small};
  padding: 0px 16px 0 16px;
  transition: 0.2s;
  gap: 0px;

  &:hover {
    filter: brightness(97%);
  }
`;

const TriangleIcon = styled.img`
  height: 15px;
  transition: 0.1s;
  ${p => p.rotated && "transform: rotate(90deg)"}
`;
