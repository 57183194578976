
import { Link } from "../../components/Link";
import { Column, Row } from "../../components/Flex";
import { Icon, icons } from "../../components/Icon";
import { Paragraph, Subtitle, Title } from "../../components/Text";

export const GeneroCard = () => {
  return (
    <>
      <Title>Secretaría de Géneros y Disidencias</Title>
      <Paragraph>
      La Secretaría de Géneros y Disidencias es una herramienta gremial que tenemos desde el Centro de Estudiantes de Humanidades, pensada en pos de construir formaciones académicas que se sostengan en la libertad identitaria y la perspectiva de género como pilares para una vida digna para las mujeres y personas LGBTQ+. Contamos con acceso a herramientas y espacios de contención como el Protocolo de Género garantizado por el Área de Género de nuestra facultad. 
      <br /><br />
      Entendemos a la UNMDP y en particular a la Facultad de Humanidades como espacios donde elegimos desarrollar nuestros proyectos de vida. Por eso, este es el lugar para defender nuestros derechos conquistados y seguir construyendo una Universidad más igualitaria y con Perspectiva de Género.
      </Paragraph>
      <Subtitle>Protocolo de actuación en casos de violencia de género en la Universidad</Subtitle>
      <Paragraph>
      Es la herramienta de denuncia y acompañamiento con la que contamos las mujeres y disidencias en caso de sufrir o tener dudas respecto de cualquier tipo de violencia de género dentro de las relaciones educativas o laborales que desarrollamos en la Universidad y ámbitos relacionados. 
      <br /><br />
      El Protocolo tiene como objetivo garantizar el acompañamiento, información y proponer diferentes cursos de acción ante situaciones de discriminación o violencia hacia las mujeres y personas del colectivo LGTTTBI en el ámbito de la Universidad Nacional de Mar del Plata.
      <br /><br />
      Rige para todas las relaciones laborales y/o educativas que se desarrollen en este ámbito: no se limitan sólo a el espacio físico de la Universidad, sino que incluye sus dependencias y anexos, las comunicaciones o contactos establecidos a través de medios tecnológicos, virtuales tales como teléfonos, internet, redes sociales y otros. 
      <br /><br />
      Podés denunciar o consultar en base a este protocolo vos mismx o grupos que hayan sido vulneradxs, así como personas que hayan presenciado o conocido la situación.
      </Paragraph>
      <Subtitle>Línea 144</Subtitle>
      <Paragraph>
      Es una línea nacional de atención, contención y asesoramiento en situaciones de violencia por razones de género. Podés comunicarte de manera gratuita las 24 hs.
      Es importante que sepas que no se trata de una línea de emergencia. Para casos de riesgo, comunicate con el 911.
      <br /><br />
      La línea funciona desde el año 2013, los 365 días del año, con equipos interdisciplinarios que contemplan todos los tipos y modalidades de violencia reconocidos en la Ley N 26.485: física, psicológica, sexual, económica y patrimonial, simbólica, política, doméstica, institucional, laboral, contra la libertad reproductiva, obstétrica, mediática, pública política y en el espacio público.
      <br /><br />
      Actualmente, la línea 144 corre peligro. A partir de la eliminación del Ministerio de las Mujeres, Géneros y Diversidad de la Nación, el 11 de diciembre de 2024, se inició un proceso de desmantelamiento de las políticas de género conquistadas a lo largo de nuestra historia. Entre ellas, esta herramienta que recibe más de 350 comunicaciones diarias, fundamentales para la prevención y la intervención en situaciones de violencia por motivos de género. Nos seguimos organizando en contra del ajuste y la eliminación de las políticas públicas que pueden salvar nuestras vidas.
      </Paragraph>
      <Row gap='8px'>
        <Icon as={icons.phone} size='16px' />
        144
      </Row>
      <Row gap='8px'>
        <Icon as={icons.whatsapp} size='16px' />
        1127716463
      </Row>
      <Row gap='8px'>
        <Icon as={icons.mail} size='16px' />
        linea144@mingeneros.gob.ar
      </Row>
      <Row gap='8px'>
        <Icon as={icons.download} size='16px' />
        App para <Link href='https://apps.apple.com/ar/app/144/id1356725934' accent target='_blank'>iOS</Link> y <Link href='https://play.google.com/store/apps/details?id=ar.gob.lalinea144' accent target='_blank'>Android</Link>
      </Row>
      <Column flex='1'>
          <Subtitle>Contacto Protocolo de Género UNMDP</Subtitle>
          <Paragraph>
            Para realizar consultas o denuncias:
            <br />
            Lunes de 14 a 18 hs
            <br />
            Martes de 9 a 13 hs
            <br />
            Jueves de 14 a 18 hs
          </Paragraph>
          <Row gap='8px'>
            <Icon as={icons.phone} size='16px' />
            (223) 6987603
          </Row>
          <Row gap='8px'>
            <Icon as={icons.mail} size='16px' />
            protocologenerounmdp@mdp.edu.ar
          </Row>
        </Column>
        <Row flexWrap="wrap" rowGap="32px">
          <Column flex="1">
            <Subtitle>Contacto Secretaría de Géneros y Disidencias CEH</Subtitle>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Coti: 2235730085
            </Row>
            <Row gap="8px">
              <Icon as={icons.phone} size="16px" />
              Tiary: 2235838889
            </Row>
            <Row>
              <Link
                href="https://www.instagram.com/secretariadegeneros.ceh/"
                accent
                target="_blank"
              >
                Instagram
              </Link>
            </Row>
          </Column>
        </Row>
    </>
  );
};