import { css } from "styled-components";
import styled from "styled-components/macro";
import { addProp } from "../app/util";

const customizeCss = p => css`
  ${p.nonSelectable && "user-select: none;"}
  ${addProp("font-size", p.size)}
  ${addProp("weight", p.weight)}
  ${addProp("line-height", p.lineHeight)}
  ${addProp("color", p.color)}
  ${addProp("text-align", p.align)}
`;

export const Span = styled.span`
  ${customizeCss}
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  font-family: "${p => p.theme.font[0]}";
  color: ${p => p.theme.color.primary};
  display: flex;
  align-items: center;
  gap: 8px;

  ${p => p.theme.mq.small} {
    font-size: 24px;
  }

  ${customizeCss}
`;

export const Subtitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: ${p => p.theme.color.primary};
  display: flex;
  align-items: center;
  gap: 8px;

  ${customizeCss}
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  ${customizeCss}
`;
