import styled from "styled-components/macro";

export const Space = styled.div.attrs({ "data-testid": "space" })`
  display: inline-block;
  height: ${p => p.height ?? "unset"};
  width: ${p => p.width ?? "unset"};
  max-height: ${p => p.maxHeight ?? "unset"};
  min-height: ${p => p.minHeight ?? "unset"};
  max-width: ${p => p.maxWidth ?? "unset"};
  min-width: ${p => p.minWidth ?? "unset"};
  background-color: ${p => (p.show ? "red" : "unset")};

  ${p => p.theme.mq.large} {
    ${p => p.mqLargeHeight && `height: ${p.mqLargeHeight};`}
    ${p => p.mqLargeWidth && `width: ${p.mqLargeWidth};`}
  }

  ${p => p.theme.mq.medium} {
    ${p => p.mqMediumHeight && `height: ${p.mqMediumHeight};`}
    ${p => p.mqMediumWidth && `width: ${p.mqMediumWidth};`}
  }

  ${p => p.theme.mq.small} {
    ${p => p.mqSmallHeight && `height: ${p.mqSmallHeight};`}
    ${p => p.mqSmallWidth && `width: ${p.mqSmallWidth};`}
  }
`;
