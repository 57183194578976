import styled from "styled-components";
import { css } from "styled-components";
import { Icon, icons } from "../Icon";

export const NavItem = ({ children, to, arrowRight, ...rest }) => {
  return (
    <Wrapper>
      <NavLink href={to} {...rest}>
        {children}
        {arrowRight && <Icon as={icons.right} color='white' />}
      </NavLink>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  ${p => p.theme.mq.large} {
    width: 100%;
    border-top: 1px solid #ffffff5c;
    display: flex;
    flex-direction: column;
  }
`;

const NavLink = styled.a`
  --main-color: ${p => p.theme.color.white};
  color: var(--main-color);
  font-size: 15px;
  transition: 0.2s;

  ${p =>
    !p.notHover &&
    css`
      &:hover {
        opacity: 0.75;
      }
    `}

  svg {
    display: none;
  }

  ${p => p.theme.mq.large} {
    padding: 16px 0;
    width: 100%;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      border-bottom: 0;
    }

    svg {
      display: inline;
    }
  }
`;
